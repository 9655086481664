@import '../../abstract/variables';
@import '../../abstract/mixin';

label.ant-radio-wrapper.company-status-setting {
    align-items: flex-start;
    .ant-radio {
        margin-top: 4px;
    }
    .note {
        font-size: 12px;
        line-height: 24px;
        color: $blue-gray-60;
        margin-bottom: 0;
    }
}

.custom-dropdown-overlay--with-separator {
    .ant-dropdown-menu {
        max-height: 532px;
        overflow-y: auto;
    }
}
