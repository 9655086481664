@import '../abstract/variables';

.html-mail_wrapper {
  background-color: #F1F1F1;
  min-height: 500px;
}

.html-mail {
  max-width: 612px;
  background-color: #fff;
  margin: 0 auto;
  padding: 0;
  font-family: 'Noto Sans JP', sans-serif;

  p {
    margin-bottom: 0;
  }

  a {
    color: #E63E55;
    text-decoration: underline;
  }

  ol, ul {
    margin: 0;
    padding-left: 20px;
  }
}

.hm_logo {
  padding: 32px 0;
  display: flex;
  justify-content: center;
}

.hm_subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px 8px;
  color: #505355;

  margin-bottom: 8px;
}

.hm_title {
  font-weight: 700;
  font-size: 28px;
  line-height: 135%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;

  margin-bottom: 32px;
}

.hm_description {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 24px;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.85);
  white-space: pre-wrap;
}

.hm_cover_image {
  width: 612px;
  height: 400px;

  margin-bottom: 32px;
}

.hm_headline {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #000000;

  padding: 0 16px;
  margin-bottom: 24px;

  &.headline- {
    &l {
      margin-top: 24px;
      margin-bottom: 24px;
      font-size: 24px;
    }
    &m {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 18px;
    }
    &s {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 6px;
    }
  }
}

.hm_text {
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  padding: 0 16px;
}

.preview-html-note-wrapper {
  max-width: 612px;
  background-color: #fff;
  margin: 0 auto;
  padding: 32px 16px 0;
  font-family: 'Noto Sans JP', sans-serif;
}
.session-edit-wrapper {
  //background-color: $blue-gray-5;
  margin: 0 auto;
  padding: 16px 24px 24px;

  &.session-detail-cards {
    border-top: 1px solid $blue-gray-10;
  }
}

.session-detail-card {
  border: 1px solid $blue-gray-20;
  background-color: #fff;
  margin-bottom: 16px;

  &--header {
    padding: 16px 24px;
    border-bottom: 1px solid $blue-gray-20;
  }

  &--body {
    padding: 24px;
  }
}

.session-detail-card-radar {
  border: 1px solid $blue-gray-20;
  background-color: #fff;
  margin: 16px;

  &--header-grey {
    padding: 12px 24px;
    border-bottom: 1px solid $blue-gray-10;
    background-color: $blue-gray-10;
  }
  &--body-grey {
    margin: 24px;
    padding: 24px;
    border: 1px solid $blue-gray-20;
    background-color: #F9F9F9;
    border-radius: 4px;
  }
}

.preview-html-note {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: 0 16px;
  background: #EDEDED;
}