@import '../../abstract/variables';
@import '../../abstract/mixin';

.seminar-category-title {
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 24px;

  &.scheduled {
    @include ResponsiveMaxWidth(MD) {
      margin-bottom: 16px !important;
    }
  }

  &.past {
    @include ResponsiveMaxWidth(MD) {
      margin-bottom: 16px !important;
    }
  }

  @include ResponsiveMaxWidth(MD) {
    font-size: 18px !important;
    font-weight: bold;
  }
}

.seminar-detail-content-btn {
  width: 128px;
  height: 45px;
  font-weight: bold;
  display: flex;
  justify-content: center;

  &.rls-btn {
    width: 160px;
  }

  &.un-rls-btn {
    width: 192px;
  }
}

.seminar-detail-content-container {
  width: fit-content;
  height: 100%;
}

.seminar-admin-detail-cover-image {
  border: 1px solid #e8e8eb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 336px;
  height: 200px;
  object-fit: cover;
}

.seminar-company-select {
  width: 350px;
}

.seminar-block-container {
  min-height: 288px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  @include ResponsiveMaxWidth(MD) {
    min-height: 241px;
  }

  &.past-seminar {
    height: 100%;
    min-height: 300px;
  }
}

.seminar-block-content {
  padding: 5px 16px 12px 16px;
  word-break: break-all;
  max-height: 137px;
  @include ResponsiveMaxWidth(MD) {
    height: 91px;
    padding: 8px 16px 12px 16px;
  }
}

.date-time-content {
  margin-bottom: 2px;
  @include ResponsiveMaxWidth(MD) {
    display: flex;
  }

  .held-this-week {
    background-color: $red-100;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 8px;
    right: 8px;
    top: 136px;
    text-align: center;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include ResponsiveMaxWidth(SM) {
      height: 32px;
      top: 128px;
    }
  }

  .date-text {
    font-size: 14px;
    color: $blue-gray-100;
    @include ResponsiveMaxWidth(MD) {
      font-size: 12px;
    }
  }
}

.seminar-block-image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-top-left-radius: 8px;
  object-fit: cover;
  background-color: $blue-gray-10;

  &.have-passed {
    border-radius: unset;
    border: 1px solid #d1d0d6;
    box-sizing: border-box;
    width: 108px;
    height: 64px;
    margin-right: 18px;
    background-size: cover;
    @include ResponsiveMaxWidth(MD) {
      margin-right: 8px;
    }
  }
}

.video-player {
  //padding-bottom: 58.14% ;
  //background-color: #222126;

  iframe, video {
    outline: none;
    border: none;
    width: 100%;
    aspect-ratio: 16/9;
  }
}

.seminar-nodata-image {
  margin-top: 0 !important;
}

.nodata-text {
  margin-top: 18px !important;
  margin-bottom: 20px !important;

  @include ResponsiveMaxWidth(MD) {
    font-size: 14px;
    color: $blue-gray-60;
  }
}

.session-link {
  display: flex;
  justify-content: center;
}

.seminar-block-content-name {
  color: $blue-gray-100;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include ResponsiveMaxWidth(MD) {
    font-size: 14px !important;
  }
}

.seminar-block-content-description {
  color: $blue-gray-100;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include ResponsiveMaxWidth(MD) {
    display: none;
  }
}

.change-state-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upcoming-seminar-col {
  max-width: 362px !important;
  height: 300px;
  @include ResponsiveMaxWidth(MD) {
    max-width: 100% !important;
    height: auto;
  }
}

.past-seminar-row {
  padding: 24px 0;
  border-bottom: 1px solid $outline;
  overflow: hidden;
  @include ResponsiveMaxWidth(MD) {
    align-items: center;
    border-bottom: none;
    padding: 0 0 24px 0;
  }

  .detail-action-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .past-seminar-btn {
    display: flex;
    justify-content: flex-end;

    .btn-browsing {
      min-width: 120px;
      @include Responsive(SM) {
        pointer-events: auto;
      }
      @include ResponsiveMaxWidth(XS) {
        display: none;
      }
    }
  }

  .info {
    @include ResponsiveMaxWidth(MD) {
      display: flex;
      flex-direction: column-reverse;
    }

    .seminar-name {
      font-size: 16px;
      color: $blue-gray-100;
      @include ResponsiveMaxWidth(MD) {
        font-size: 14px;
        word-break: break-all;
      }
    }

    .seminar-date-panel {
      @include ResponsiveMaxWidth(MD) {
        white-space: nowrap;
      }

      .seminar-date {
        padding-left: 10px;
        font-size: 14px;
        color: $blue-gray-100;
        @include ResponsiveMaxWidth(MD) {
          font-size: 12px;
          padding-left: 4px;
        }
      }

      .seminar-time {
        font-size: 14px;
        color: $blue-gray-100;
        @include ResponsiveMaxWidth(MD) {
          font-size: 12px;
        }
      }
    }
  }
}

.cover-img-container {
  display: flex;
  justify-content: center;

  &.admin-detail {
    margin-right: 32px;
  }

  &.float-image-and-description {
    display: block;
    max-width: 100%;
    text-align: justify;
    //max-width: 688px;
    //margin-left: auto;
    margin-right: auto;

    > .ant-image, .no-image-background {
      float: left;
    }
  }

  .description {
    min-height: 224px;
  }
}

.seminar-detail-action-panel {
  padding: 24px;
  margin-top: 40px;
  background: $blue-gray-5;
  border-radius: 8px;

  @include ResponsiveMaxWidth(SM) {
    height: 247px;
  }
  @include ResponsiveTablet(Ipad) {
    height: 104px;
  }

  .action-panel {
    display: flex;
    justify-content: flex-end;
    @include ResponsiveMaxWidth(SM) {
      flex-direction: column-reverse;
      align-items: center;
    }
    @include ResponsiveTablet(Ipad) {
      flex-direction: row;
    }
  }

  .date-time-panel {
    .date-time-text {
      font-weight: bold;
      font-size: 20px;
      @include ResponsiveMaxWidth(SM) {
        font-size: 18px;
      }
    }

    @include ResponsiveMaxWidth(SM) {
      text-align: center;
    }
    @include ResponsiveTablet(Ipad) {
      text-align: unset;
    }
  }
}

.sticky-container {
  &.stick {
    .seminar-detail-action-panel {
      position: fixed;
      right: 0;
      left: 220px;
      border-radius: 0;
      bottom: 0;
      border-top: 1px solid $blue-gray-20;
      padding: 16px 48px;
      background-color: #fff;
      z-index: 1;

      .floatbar-sticky-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 710px;
        margin: 0 auto;
      }

      @include ResponsiveMaxWidth(MD) {
        left: 0;
        height: 61px;
        display: inline;
        padding: 6px 16px;
      }

      .action-panel {
        justify-content: center;
        @include ResponsiveMaxWidth(SM) {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .google-calendar-btn {
            display: none;
          }
        }
      }

      .date-time-panel {
        display: flex;
        align-items: center;
        @include ResponsiveMaxWidth(SM) {
          display: block;
        }

        .date-time-title {
          @include ResponsiveMaxWidth(SM) {
            font-size: 14px !important;
          }
          @include ResponsiveMaxWidth(SE) {
            font-size: 12px !important;
          }
        }

        .date-time-text-ctn {
          padding-left: 8px;
          padding-right: 24px;
          @include ResponsiveMaxWidth(SM) {
            padding: 0;
          }
        }

        .date-time-text {
          font-weight: normal;
          @include ResponsiveMaxWidth(SM) {
            font-weight: normal;
            font-size: 16px;
          }
          @include ResponsiveMaxWidth(SE) {
            font-size: 14px !important;
          }
        }

        @include ResponsiveMaxWidth(SM) {
          text-align: unset;
        }
      }

      .admission-floatbar-btn {
        min-width: 120px;
        height: 45px;
        font-weight: bold;
        justify-content: center;
        margin-left: 28px;

        @include ResponsiveMaxWidth(SM) {
          margin-left: 0;
          min-width: 96px !important;
        }
      }
    }
  }
}

.admission-btn {
  min-width: 120px;
  height: 45px;
  font-weight: bold;
  justify-content: center;
  margin-left: 24px;

  @include ResponsiveMaxWidth(SM) {
    margin-left: 0;
    width: 160px;
    margin-bottom: 20px;
  }

  @include ResponsiveTablet(Ipad) {
    margin-left: 24px;
    margin-bottom: 0;
    width: 120px;
  }
}

.back-to-list-btn {
  font-weight: bold;
  font-size: 16px;
  margin-top: 44px;
}

.seminar-badge {
  color: #fff;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  background: $blue-gray-30;
  margin-bottom: 4px;
  align-self: flex-start;

  @include Responsive(MD) {
    display: none;
  }
}

.seminar-admin-detail-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
}

.no-image-background {
  background: $blue-gray-10;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;

  &.block {
    height: 160px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @include ResponsiveMaxWidth(SM) {
      //height: 128px;
      padding-bottom: 32px;
      height: 160px;
    }
  }

  &.horizontal {
    height: 188px;
    width: 257px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: unset !important;
    -webkit-border-top-right-radius: unset !important;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    @include ResponsiveMaxWidth(MD) {
      border-top-right-radius: 8px !important;
      -webkit-border-top-right-radius: 8px !important;
      border-bottom-left-radius: unset !important;
      -webkit-border-bottom-left-radius: unset !important;
      width: auto;
      height: 128px;
    }
  }

  &.no-cover {
    background: $blue-gray-5;
    width: 336px;
    height: 200px;
    border-radius: 8px;
  }

  &.no-cover-in-modal {
    background: $blue-gray-5;
    width: 800px;
    height: 225px;
    border-radius: 8px;
    max-width: 100%;
  }

  &.past-seminar-image {
    min-height: 64px;
    width: 108px;
    margin-right: 18px;
    @include ResponsiveMaxWidth(MD) {
      margin-right: 8px;
    }
  }

  .content {
    color: $blue-gray-40;
    font-weight: bold;
  }
}

.display-zoom-info {
  margin-top: 2px;

  .ant-btn-link {
    white-space: normal;
    display: block;
    text-align: left;
    word-break: break-word;
    line-height: 24px;

    span {
      text-decoration: none;
    }
  }

  .ant-typography {
    line-height: 32px;
  }
}

.target-program-table {

  .ant-table-row {
    &:hover {
      .anticon {
        color: $blue-purple-40;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row > td {
    //background-color: $blue-gray-5 !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: $blue-purple-10 !important;
  }

  .ant-table-tbody > tr.ant-table-row.checked-row > td {
    background-color: $blue-purple-10 !important;
  }

  &.collapse-table .ant-table.ant-table-middle .ant-table-thead > tr > th {
    border-top: 0;
    border-bottom: 0;
  }

  &.collapse-table .ant-table-tbody {
    border-bottom: 0;
  }

  &.collapse-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0 8px;
  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 9px 8px;
  }

  .ant-table-column-sorters {
    justify-content: start;

    .ant-table-column-sorter {
      margin-left: 6px;
      margin-top: 1px;
    }
  }
}

.invalid-time-style {
  &.invalid-time {
    &:after {
      color: $error;
      content: '無効な時間です';
      position: absolute;
      z-index: 9;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .ant-select-selector {
      border-color: $error;
    }
  }
}

.hv-company-name {
  background: $blue-purple-10;
  white-space: nowrap;
  border-radius: 4px;
  padding: 2px 8px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 24px;
}

.small-note-seminar-archive {
  display: flex;
  align-items: center;

  .text-small-seminar-archive {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #222126;
    background-color: #A5A6F6;
    border-radius: 4px;
    padding: 2px 8px;
    min-width: 56px;
  }
}

.video-container.paused {
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
}

.mini-archive-overlay {
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.archive-status-tag {
  white-space: nowrap;
  padding: 3px 8px;
  background-color: #d9d9d9;
  border-radius: 6px;
  font-size: 12px;

  &.is-video-public {
    background: #a5a6f6;
  }
}