@import '../../abstract/variables';

.consultation-detail {
    &-header {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
}

.aside-table {
    .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.collapse-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.collapse-table .ant-table-thead > tr > th {
        font-size: 12px;
        line-height: 18px;
    }

    &.collapse-table table {
        font-size: 12px;
        line-height: 18px;
    }

    //.ant-table-selection-column {
    //    display: none;
    //}

    .ant-table.ant-table-middle .ant-table-tbody > tr {
        &:hover {
            > td {
                background-color: #f9f9f9;
            }
        }
        &.tdt-row-selected {
            > td {
                background-color: #fff !important;
            }
        }

        > td {
            background-color: $bg-gray;
        }
    }

    &.collapse-table .ant-table-tbody {
        border-bottom: 0;
    }

    &.collapse-table .ant-table-tbody tr {
        border-top: 0;
        cursor: pointer;
    }

    &.collapse-table .ant-table-tbody tr:not(:first-child) {
        &:after {
            background-color: #c4c4c4;
            height: 1px;
            content: '';
            display: block;
            position: absolute;
            right: 16px;
            left: 16px;
        }
    }
}

.back-btn.with-title.back-at-interview {
    margin-left: -40px !important;
    margin-top: 0;
}

.feed-back-content {
    white-space: pre-wrap;
}

.color-ellipse{
    &-red {
        color: $ellipse-red !important;
    }
    &-dark-blue {
        color: $ellipse-dark-blue !important;
    }
    &-yellow {
        color: $ellipse-yellow !important;
    }
    &-light-blue{
        color: $ellipse-light-blue !important;
    }
    &-green {
        color: $ellipse-green !important;
    }
}
