@import '../abstract/variables';

.company-name-extra {
    display: flex;
    margin-top: 12px;
    font-size: 14px;
    line-height: 21px;
    color: $blue-gray-60;

    .anticon {
        color: #666;
    }
}

.ant-btn.btn-pink {
    color: $pink;

    &:hover,
    &:active {
        border-color: $pink;
        color: $pink;
    }
}

h1.title-company-setting {
    font-size: 16px;
    font-weight: bold;
}

.company-setting {
    font-size: 20px;
}

.title-company-value {
    font-size: 20px;
    line-height: 30px;
}

.title-company-note {
    font-size: 14px;
    line-height: 21px;
    color: $blue-gray-60;
}

.ant-modal-header .not-delete-title {
    color: $pink;
}

.delete-action-panel {
    text-align: right;
}

.ant-modal-body {
    button {
        border: none;
        box-shadow: none;
        padding-left: 0px;
        padding-right: 0px;

        .title-button {
            padding: 12px 16px 12px 16px;
        }
    }
    .delete-button {
        color: $pink;
    }

    .delete-button:hover,
    .send-button:hover,
    .cancel-button:hover {
        text-decoration: underline;
    }
}

// reservation-frame-detail
.reservation-frame-detail {
    padding-bottom: 24px;
}

.float-bar-company {
    width: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    .title-total-employee {
        order: 3;
        margin-right: 16px;
    }
    .number-total-employee {
        margin-right: 16px;
        order: 2;
    }
    button {
        order: 1;
    }
}
