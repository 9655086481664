@import '../../abstract/variables';

.client-message-row {
    padding: 24px 0;
    border-bottom: 1px solid $outline;

    .message-icon-box {
        background-color: $secondary;
        width: 48px;
        height: 48px;
        display: inline-flex;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        position: relative;

        &.seen {
            background-color: $secondaryVariant;
        }

        &:not(.seen) {
            .line-icon-box {
                display: none;
            }
        }

        .svg-icon {
            color: #fff;
        }

        .line-icon-box {
            width: 20px;
            height: 20px;
            display: inline-flex;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            background-color: $secondary;
            position: absolute;
            bottom: 0;
            right: 0;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        }
    }
}

.client-message-thread-row {
    padding-bottom: 40px;
    border-bottom: 1px solid $color-outline;

    + .client-message-thread-row {
        padding-top: 40px;
    }

    &:last-child {
        border-bottom: 0;
        @include ResponsiveMaxWidth(XS) {
            margin-bottom: 60px;
        }
    }
}

.message-thread-item {
    &-author,
    &-date {
        font-size: 14px;
        line-height: 21px;
    }

    &-date {
        color: $color-medium;
    }

    &-content {
        font-size: 16px;
        line-height: 24px;
    }
}

.msg-badge {
    color: #fff;
    border-radius: 8px;
    padding: 2px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;

    &-secondary {
        background-color: $secondary;
    }

    &-disable {
        background-color: $color-medium;
    }
}

.client-message-item {
    color: #000;
    opacity: 0.87;
    font-size: 14px;
    line-height: 21px;
    word-break: break-word;
    @include ResponsiveMaxWidth(XS) {
        display: flex;
        flex-direction: column;
    }

    .message-item-title {
        font-size: 20px;
        line-height: 30px;
        word-break: break-word;
        @include ResponsiveMaxWidth(XS) {
            order: 2;
            width: 100%;
            word-break: break-word;
        }
    }
}
.message-item-content {
    word-break: break-word;
    white-space: pre-line;
    max-height: 120px;
    overflow: hidden;
    @include ResponsiveMaxWidth(XS) {
        order: 3;
        width: 100%;
        padding-bottom: 4px;
        word-break: break-word;
    }
}
.client-message {
    word-break: break-word;
    @include ResponsiveMaxWidth(XS) {
        display: flex;
        flex-direction: column;
        order: 1;
        width: 100%;
        word-break: break-word;
    }
}

.client-message .message-msg-badge {
    @include ResponsiveMaxWidth(XS) {
        padding-bottom: 4px;
        order: 1;
    }
}
.client-message .client-message-time {
    @include ResponsiveMaxWidth(XS) {
        order: 2;
    }
}

.btn-outline {
    @include ResponsiveMaxWidth(SM) {
        width: 100%;
    }
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
    }
}
.btn-outline-message {
    @include ResponsiveMaxWidth(SM) {
        padding-right: 5%;
    }
    @include ResponsiveMaxWidth(XS) {
        padding-right: 0px;
        padding-top: 24px;
    }

    @include Responsive(XXL) {
        margin-left: 56px;
    }
}
.btn-outline-detail {
    width: 120px;
    height: 45px;
    justify-content: center;
    border: 1px solid $blue-gray-20;
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
        justify-content: center;
        margin-right: 10px;
    }
}

.message-thread-end {
    background-color: $bg-gray;
    justify-content: center;
}

.message-thread-float-bar {
    height: unset;
    padding-top: 32px;

    @include ResponsiveMaxWidth(MD) {
        height: 84px;
    }
    .resizable-button {
        @include ResponsiveMaxWidth(MD) {
            display: none;
        }
    }
    .btn-message-detail {
        border-radius: 8px;
        @include ResponsiveMaxWidth(MD) {
            border-radius: 4px;
        }
    }
    .btn-message-detail-name {
        @include ResponsiveMaxWidth(MD) {
            display: none;
        }
    }
    .btn-message-detail-icon {
        display: none;
        @include ResponsiveMaxWidth(MD) {
            display: block;
            padding-top: 4px;
        }
    }
}

// resizable
.vertical-resizable-wrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    @include ResponsiveMaxWidth(MD) {
        align-items: normal;
        padding-top: 5px;
    }

    .ant-input-textarea,
    .ant-input {
        height: 100%;
        @include ResponsiveMaxWidth(MD) {
            height: 70%;
        }
        @include ResponsiveMaxWidth(SE) {
            height: 40px;
        }
    }

    .ant-input {
        resize: none;
    }

    .ant-btn {
        width: 80px;
        height: 74px;
        min-width: unset;
        @include ResponsiveMaxWidth(MD) {
            height: 70%;
            width: 60px;
        }
        @include ResponsiveMaxWidth(SE) {
            height: 40px;
            width: 40px;
        }
    }
}

.resizable-button-container {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    //top: -28px;
    top: -44px;
}

.resizable-button {
    background-color: $primary;
    border-radius: 12px;
    color: #fff;
    width: 80px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: n-resize;
    margin: 0 auto;
}

// to component
.circle-icon-with-bg {
    background-color: $secondary;
    width: 48px;
    height: 48px;
    display: inline-flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    &.with-check {
        background-color: $secondaryVariant;
    }

    &.periodic-diagnosis {
        background-color: $blue-purple-80;
    }

    &:not(.with-check) {
        .circle-icon-check {
            display: none;
        }
    }

    .svg-icon {
        color: #fff;
    }

    .circle-icon-check {
        width: 20px;
        height: 20px;
        display: inline-flex;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        background-color: $secondary;
        position: absolute;
        bottom: 0;
        right: 0;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
}

.send-button {
    @include ResponsiveMaxWidth(XS) {
        margin-left: 0 !important;
    }
}

.message-thread-item-content {
    word-break: break-word;
    white-space: pre-line;
}

.client-message-threads {
    overflow-y: auto;

    @include ResponsiveMaxWidth(XS) {
        height: unset !important;
    }
}

.fixed-title-msg {
    min-height: calc(100vh - 148px);

    .client-message-threads {
        max-height: calc(100vh - 251px);
        flex: 1;
        height: auto;

        &.thread-as-email-type {
            max-height: unset !important;
        }
    }

    @include ResponsiveMaxWidth(XS) {
        min-height: unset;

        .client-message-threads {
            max-height: unset;
            flex: 1;
            height: auto;
        }
    }
}

.msg-user-side {
    padding-top: 24px;

    @include ResponsiveMaxWidth(SM) {
        padding-top: 0;
    }
}
