@import '../abstract/variables';

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f4f4f4;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}

.ovr-dropdown-inner {
  width: unset !important;
}

.ovr-dropdown-width-auto {
  width: unset !important;
  max-width: 650px;

  div[role='listbox'] {
    width: 0;
  }
}

.dropdown-popup-width {
  max-width: 208px;
}

.dropdown-popup-height {
  .rc-virtual-list-holder {
    max-height: unset !important;
  }
}

.label-no-after {
  .ant-select-selection-item {
    &:after {
      display: none !important;
    }
  }
}

.mobile-option-padding {
  @include ResponsiveMaxWidth(SM) {
    .ant-select-item {
      padding: 10px 12px;
    }
  }
}

.ant-select {
  &.tdt-select {
    > .ant-select-selector {
      border-color: $color-outline;
      background-color: transparent;
      align-items: center;
    }

    &:hover {
      .ant-select-selector {
        border-color: $color-outline;
      }
    }

    &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      //padding-left: 0;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
      border-color: $color-outline;
      box-shadow: none;
    }

    .ant-select-arrow {
      color: $color-medium;
    }

    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 24px;
    }

    &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }

    &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 8px;
    }

    // add select-gray class
    &.select-gray {
      &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 16px;
        height: 40px;
        background: $blue-gray-5 !important;
      }

      .ant-select-arrow {
        top: 14px;
        right: 10px;
      }

      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 12px;
      }
    }

    &.white-override {
      &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        background: #fff !important;
        transition: unset;
      }

      &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: $blue-gray-5 !important;
      }
    }

    // add select-40
    &.select-40 {
      &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 16px;
        height: 40px;
      }

      .ant-select-arrow {
        top: 14px;
        right: 10px;
      }

      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 12px;
      }

      &.limit-text {
        .ant-select-selection-item {
          max-width: 158px;
        }
      }
    }

    .ant-select-arrow {
      top: 10px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }
}

.interview-sheet-select {
  &.ant-select.tdt-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 16px;
  }
}

.ant-select-arrow-interview {
  .ant-select {
    &.tdt-select {
      &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        height: 41px;
      }
    }

    .ant-select-arrow {
      top: 15px;
    }
  }
}

//.ant-select-selector {
//    background: $blue-gray-5 !important;
//}

.tdt-dropdown {
  .ant-select-item {
    font-size: 14px;
    line-height: 21px;
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: $error !important;
}

.select-pagination-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $blue-gray-100;
}

.select-pagination-wrapper {
  position: absolute;
  right: 9px;
}

.absolute-select {
  position: absolute;
  //top: 8px;
  bottom: 13px;
}

.absolute-select-description {
  position: absolute;
  top: 14px;
}

.relative-parent-select {
  position: relative;
  box-shadow: none;
  border: 1px solid $color-outline;
  top: -1px !important;
  z-index: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select-last-child-relative {
  > div:last-child {
    position: unset !important;
  }
}

.select-as-tags {

  .ant-select-arrow {
    width: 24px;
    height: 24px;
    //top: 16px;
    right: 8px;
    color: $blue-gray-60;
    top: calc(50% - 8px);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    //min-height: 44px;
    min-height: 46px;
    background-color: #fff;
    padding-left: 12px;
    height: unset;
  }

  &.ant-select-single {
    .ant-select-selection-placeholder {
      //line-height: 42px;
      line-height: 30px;
      padding-top: 4px;
      padding-bottom: 4px;

      &::after {
        display: none;
      }
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    pointer-events: unset !important;
    flex-wrap: wrap;
  }

  // for remove icon
  .ant-select-selection-search-input, .ant-select-selection-search {
    pointer-events: none;
  }

  .select-tag-item {
    font-size: 12px;
    line-height: 28px;
    color: $blue-gray-100;
    background-color: $blue-purple-10;
    padding: 0 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 4px;
  }
}
