@import '../abstract/variables';

.custom-dropdown-overlay {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 46px;
  }

  &--with-separator {
    .ant-dropdown-menu-item:not(:last-child) {
      position: relative;

      &:after {
        background-color: $gray-separator;
        height: 1px;
        content: '';
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 0;
      }
    }
  }
}

.dots-dropdown {

  .ant-dropdown-menu {
    min-width: 160px;
    border: 1px solid $blue-gray-20;
    border-radius: 0px;
  }

  &.menu-less-py {
    .ant-dropdown-menu {
      padding: 8px 0;
      top: unset;
    }
  }
}

.top-dots-dropdown {

  .ant-dropdown-menu {
    top: 10px;
  }
}

.distribution-dropdown {
  .ant-dropdown-menu {
    height: 95px;
    min-width: 176px;
    padding: 8px 0;
  }
}

.ant-dropdown-menu {
  min-width: 320px;
  padding: 0;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 16px;
      right: 16px;
      bottom: 0;
      background-color: #d0d0d0;
    }

    &:last-child:after {
      display: none;
    }
  }
}

.ant-select-dropdown {
  z-index: 10001;
}


