@import '../abstract/variables';

.outline-explanation-input {
    min-height: 160px !important;
    width: 480px;
}

.ant-select.tdt-select.tdt-select-40 {
    .ant-select-selector {
        background: $gray-input;
        height: 40px !important;
    }

    .ant-select-arrow {
        top: 14px;
    }
}

.program-btn-create {
    margin-bottom: 18px;
}

.program-input {
    max-width: 320px;
    width: 320px;
    height: 40px;
}

.dot-status {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;

    &-blue {
        background: $blue-100;
    }
}

.program-booking-option {
    border-radius: 4px;
    background-color: transparentize($pink, 0.9);
    padding: 8px 16px 8px 40px;
    max-width: 488px;
    color: $blue-gray-100;
    position: relative;

    .info-icon {
        position: absolute;
        top: 13.33px;
        left: 17.33px;
    }
}

.no-image-as-image {
    background-color: $blue-gray-10;
    font-weight: 700;
    font-size: 8px;
    line-height: 21px;
    color: $blue-gray-40;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.program-draggable-row {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    background-color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: $blue-gray-100;
}

div[data-rbd-droppable-id] {
    min-height: 40px;
}
