@import '../abstract/variables';
@import '../abstract/mixin';

.page-element-block {
  max-width: 528px;
  padding: 32px 24px;
  background-color: $blue-gray-5;
  margin-bottom: 32px;

  .ant-input {
    background-color: #fff;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.element-action-btn {
  height: 45px;
}

.element-common-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
}

.element-item-label {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 8px;
}

.page-element-heading-size {
  .ant-radio-button-wrapper {
    width: 64px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-color: $blue-gray-20;

    &:first-child {
      border-color: $blue-gray-20;
    }

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: $blue-purple-100;
    }

    &-checked {
      background-color: $blue-purple-100;
      border-color: $blue-purple-100 !important;

      span {
        color: #fff;
      }
    }
  }
}

.add-image-button {
  .ant-upload-list-picture-card-container {
    width: 296px;
    height: auto;
    margin: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 296px;
    height: auto;
    margin: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    background-color: transparent;
  }

  .ant-upload-list-item-actions {
    opacity: 1;
    right: 8px;
    top: 8px;
    transform: none;
    left: unset;

    svg {
      color: #fff;
    }
  }

  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
    background: $pink;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      display: flex;
    }
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    opacity: 1;
  }
}

.person-profile-element-thumb {
  .add-image-button .ant-upload-list-picture-card-container,
  .add-image-button .ant-upload-list-picture-card .ant-upload-list-item {
    width: 128px;
    height: 128px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
    //opacity: 1;
  }

  .add-image-button .ant-upload-list-picture-card .ant-upload-list-item {
    overflow: hidden;
    border-radius: 0;

    &:after {
      content: '';
      position: absolute;
      background-color: transparent;
      border: 32px solid rgba(#c4c4c4, 0.64);
      border-radius: 100%;
      width: 192px;
      height: 192px;
      top: -32px;
      left: -32px;
    }
  }
}

.person-profile-container {
  margin-top: 24px;

  &.client-detail {
    @include ResponsiveMaxWidth(MD) {
      align-items: flex-start;
    }
  }

  .person-profile-description {
    padding-left: 70px !important;
    @include ResponsiveMaxWidth(MD) {
      padding-left: 16px !important;
    }
  }
}

.person-profile-avatar {
  width: 128px;
  height: 128px;

  &.client-detail {
    @include ResponsiveMaxWidth(MD) {
      width: 80px;
      height: 80px;
    }
  }
}

.render-page-container {
  padding: 8px 32px;
  overflow-y: scroll;
  max-height: 600px;
}

.render-session-page-container {
  padding: 16px 24px;
  background-color: #FFFFFF;
}

.horizontal-line {
  width: 100%;
  /* Blue-Gray-10 */
  border: 1px solid #E8E8EB;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.vertical-line {
  border-right: 1px solid #D1D0D6;
  top: -10px;
  bottom: -10px;
  position: absolute;
  color: #D1D0D6;

  &::after {
    content: attr(data-percent);
    position: absolute;
    bottom: -20px;
    left: -10px;
    color: #4C4A55;
    font-weight: 400;
    font-size: 12px;
  }
}

.hor-line-slim {
  border-bottom: 1px solid $blue-gray-10;
}

.ver-line-slim {
  border-right: 1px solid $blue-gray-20;
  height: 32px;
}

.percentage-icon {
  align-self: end;
}

.seminar-render-page-cover-img {
  object-fit: cover;
  border: 1px solid #e8e8eb;
  box-sizing: border-box;
  border-radius: 8px;
  height: 225px;
  max-width: 100%;
  width: 800px;
}

.seminar-render-page-description {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 32px;
  white-space: pre-wrap;
}

.counselor-questionnaire-render-page-description {
  white-space: pre-wrap;
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  width: 100%;
}

.screen-after-answer-preview-mobile-res {
  max-width: 343px;
  margin-left: auto;
  margin-right: auto;
}

.seminar-render-page-name {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin: 32px 0px 8px;
  word-break: break-word;
}

.seminar-render-page-heading {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 8px;
}

.seminar-render-page-text {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 32px;
  white-space: pre-wrap;

  a {
    color: #E63E55;
    text-decoration: underline;
  }
}

.seminar-render-page-img {
  &.img-description {
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 20px;
    color: $blue-gray-60;
  }
}

.text-editor-for-profile {
  .ql-toolbar.ql-snow {
    position: unset;
    padding-top: 4px;
  }
}

.client-render-page-container {
  margin-top: 16px;
  @include Responsive(SM) {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -49px;
  }
}

.ql-snow .ql-tooltip.ql-flip {
  margin-left: 85px;
  z-index: 9999;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'リンク入力:';
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: '保存';
}

.ql-snow .ql-tooltip::before {
  content: 'URLへのアクセス:';
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: '編集';
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: '削除';
}

.hide-posted-in-announcement {
  .element-item {
    & + div {
      display: none;
    }
  }
}
