@import '../abstract/variables';
.drop-file-input {
  position: relative;
  width: 400px;
  height: 200px;
  border: 2px dashed black;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f5f8ff;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

