.account {
    height: 111px;
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    @include ResponsiveMaxWidth(MD) {
        height: 150px;
    }
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
    }
}
.btn-account {
    width: 144px;
    justify-content: center;
    float: right;
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }
}
.form-pass {
    background: #f4f4f4;
}

.btn-cancel {
    border: none;
    color: $pink;
}
.btn-send {
    border: none;
}

.btn-cancel :hover {
    color: $pink;
}

.btn-float-bar {
    width: 138px;
    height: 45px;
    justify-content: center;
}
.float-bar-account {
    position: fixed;
    bottom: 0;
    left: 220px;
    right: 0;
    height: 80px;
    border-top: 1px solid #d0d0d0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    justify-content: space-between;

    .ant-btn {
        min-width: 120px;
        justify-content: center;
    }

    @include ResponsiveMaxWidth(XL) {
        left: 0;
    }
}

.title-account {
    color: $pink;
}

.btn-verify-code {
    border: none;
}
.btn-code {
    padding-left: 300px;
}
.btn-pl {
    padding-left: 172px;
}
.btn-setting {
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
    }
    .btn-setting-responsive {
        width: 120px;
        height: 45px;
        justify-content: center;
        @include ResponsiveMaxWidth(XS) {
            width: 100%;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.title-name-setting {
    @include ResponsiveMaxWidth(MD) {
        justify-content: center;
    }
    @include ResponsiveMaxWidth(XS) {
        justify-content: center;
    }
    .main-content {
        @include ResponsiveMaxWidth(SM) {
            width: 100%;
        }
    }
}
.input-name-setting {
    @include ResponsiveMaxWidth(MD) {
        width: 100%;
    }
    @include ResponsiveMaxWidth(SM) {
        width: 100%;
    }
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
    }
}

.input-setting {
    width: 232px;
    @include ResponsiveMaxWidth(MD) {
        width: 100%;
    }
}
.input-update-email {
    width: 280px;
    @include ResponsiveMaxWidth(MD) {
        width: 100%;
    }
}
.setting-email {
    @include ResponsiveMaxWidth(SM) {
        width: 100%;
    }
}

.pointer-events-none {
    pointer-events: none !important;
}

.account-with-btn-right {
    text-align: right;

    .btn-account {
        float: unset;
    }

    .btn-account-delete-btn {
        @include ResponsiveMaxWidth(XS) {
            margin-top: 32px !important;
            width: 100%;
            justify-content: center;
        }
    }
}