@import '../../abstract/variables';

.user-information-row {
    min-height: 100px;
    width: 100%;
    border-bottom: 1px solid $blue-gray-20;
    .program-action-panel {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0;
        margin-top: 24px;
    }
}
