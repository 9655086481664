@import '../../abstract/variables';
@import '../../abstract/mixin';

.inquiry-description {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
    @include ResponsiveMaxWidth(MD) {
        font-size: 14px;
        margin-top: 0;
    }
}

.inquiry-note {
    display: flex;
    border: 1px solid $blue-gray-20;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 18px;
    .td-info-icon svg path {
        fill: $blue-gray-60;
    }

    .inquiry-note-title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $blue-gray-100;

        @include ResponsiveMaxWidth(MD) {
            font-size: 14px;
        }
    }

    .inquiry-note-content {
        font-size: 16px;
        line-height: 24px;
        color: $blue-gray-60;

        @include ResponsiveMaxWidth(MD) {
            font-size: 14px;
        }
    }
}

.faq-link {
    text-decoration: underline;
}

.inquiry-select {
    width: 240px !important;
}

.inquiry-text-area {
    min-height: 144px !important;
    width: 480px;
    @include ResponsiveMaxWidth(MD) {
        margin-bottom: 60px;
    }
}

.inquiry-btn {
    @include ResponsiveMaxWidth(MD) {
        width: 120px;
    }
}
