@import '../../abstract/variables';
@import '../../abstract/mixin';

.register-step {
    display: flex;
    justify-content: center;
    align-items: center;

    .register-step__normal {
        display: flex;
        padding: 6px 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $blue-gray-60;
        @include ResponsiveMaxWidth(XS) {
            padding: 6px 6px;
        }
    }

    .register-step__active {
        background: #f0ebf7;
        border-radius: 4px;
        color: $primary;
    }

    .arrow-right svg path {
        fill: #666666;
    }
}

.registration-complete {
    display: flex;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 18px;
    .td-info-icon svg path {
        fill: $blue-gray-60;
    }

    .title-fill-interview {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
    }

    .title-confirm-email {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;

        color: #767384;
    }
    .title-confirm-email-message {
        font-size: 16px !important;
    }

    @include ResponsiveMaxWidth(XS) {
        margin: 0 14px;
    }
}
