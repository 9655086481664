@import '../../abstract/variables';
@import '../../abstract/mixin';

.vertical-container {
  margin-right: 22px;
  margin-bottom: 30px;
  max-height: 314px;
  @include ResponsiveMaxWidth(XS) {
    margin: 0;
  }

  .seminar-block-link {
    color: $blue-gray-100;

    &:hover {
      color: $blue-gray-100;
    }

    &.past {
      @include Responsive(SM) {
        pointer-events: none;
      }
    }

    &.client-program-block {
      max-width: 346px;
      height: 291px;
      margin-bottom: 16px;
      margin-right: 16px;
    }
  }

  .program-block {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 100%;
    max-width: 346px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    h3,
    button {
      flex-grow: 0;
    }

    p {
      flex-grow: 1;
    }

    button {
      position: relative;

      .anticon {
        position: absolute;
        left: 16px;
      }
    }

    .ant-image {
      @include ResponsiveMaxWidth(SM) {
        height: 128px !important;
      }
    }

    .content-container {
      padding: 16px;
      max-height: 157px;

      .program-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 16px;
        font-weight: 500;
        @include ResponsiveMaxWidth(SM) {
          font-size: 14px;
          -webkit-line-clamp: 2;
        }
      }

      .program-description {
        font-size: 16px;
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 4px;
        @include ResponsiveMaxWidth(SM) {
          font-size: 14px;
        }
      }

      .same-day-badge {
        border-radius: 22px !important;
        margin-bottom: 4px;
        max-width: 88px;
        @include ResponsiveMaxWidth(SM) {
          margin: 2px 0 4px 0;
        }
      }

      @include ResponsiveMaxWidth(SM) {
        min-height: 44px;
        padding: 8px 16px 12px 16px;
      }
    }

    @include ResponsiveMaxWidth(XS) {
      max-width: 100%;
      margin-bottom: -10px;
    }
    @include Responsive(XS) {
      height: 321px;
      min-width: 346px;
    }
    @include ResponsiveMaxWidth(SM) {
      height: auto;
      margin-bottom: 0;
    }
    @include ResponsiveMaxWidth(XS) {
      margin-bottom: 0px;
    }
  }

  .seminar-block-image {
    @include ResponsiveMaxWidth(SM) {
      height: 128px !important;
      width: 100%;
    }
  }
}

.horizontal-container {
  max-height: 284px;
  max-width: 730px;

  .seminar-block-link {
    color: $blue-gray-100;

    &:hover {
      color: $blue-gray-100;
    }

    &.past {
      @include Responsive(SM) {
        pointer-events: none;
      }
    }

    &.client-program-block {
      max-width: 346px;
      height: 291px;
      margin-bottom: 16px;
      margin-right: 16px;
    }
  }

  .program-block {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 730px;
    height: 188px;
    font-size: 14px;
    display: flex;
    flex-direction: row;

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    h3,
    button {
      flex-grow: 0;
    }

    p {
      flex-grow: 1;
    }

    button {
      position: relative;

      .anticon {
        position: absolute;
        left: 16px;
      }
    }

    .ant-image {
      height: 128px !important;
    }

    .content-container {
      padding: 8px 24px;
      height: 188px;
      width: 474px;
      @include ResponsiveMaxWidth(SM) {
        height: 150px !important;
        padding: 10px 16px 12px 16px;
      }
      @include ResponsiveMaxWidth(MD) {
        width: 100%;
      }

      .program-name {
        font-size: 20px;
        font-weight: bold;
        @include ResponsiveMaxWidth(SM) {
          font-size: 16px;
          font-weight: 500;
        }

        &.first-time {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @include ResponsiveMaxWidth(SM) {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .program-description {
        font-size: 16px;
        padding-top: 4px;
        @include ResponsiveMaxWidth(SM) {
          font-size: 14px;
        }

        &.first-time {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          @include ResponsiveMaxWidth(SM) {
            -webkit-line-clamp: 3;
          }
        }
      }

      .same-day-badge {
        border-radius: 22px !important;
        margin: 8px 0 0;
        max-width: 88px;
        @include ResponsiveMaxWidth(SM) {
          margin: 0 0 4px 0;
        }
      }
    }

    @include ResponsiveMaxWidth(MD) {
      height: 278px;
      flex-direction: column;
      max-width: 100%;
    }
  }

  .seminar-block-image {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: unset !important;
    -webkit-border-top-right-radius: unset !important;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
    object-fit: cover;
    background-color: $blue-gray-10;
    width: 256px;
    height: 188px !important;
    @include ResponsiveMaxWidth(MD) {
      height: 128px !important;
      width: 100%;
      border-top-right-radius: 8px !important;
      -webkit-border-top-right-radius: 8px !important;
      border-bottom-left-radius: unset !important;
      -webkit-border-bottom-left-radius: unset !important;
    }
  }

  @include ResponsiveMaxWidth(MD) {
    max-height: 292px;
  }
}

.reservation-form {
  .date-time-picker {
    display: flex;

    @include mobile {
      flex-direction: column;
      .ant-form-item:first-child {
        margin-bottom: 16px;
      }
    }

    @include above-mobile {
      .ant-form-item:first-child {
        margin-right: 16px;
      }
    }
  }

  .message {
    background-color: #f5f6f6;
    width: 100%;
    max-width: 528px;
    padding: 24px;

    textarea {
      background-color: #ffffff;
      height: 88px;
    }
  }
}

.reservation-container {
  @include ResponsiveMaxWidth(MD) {
    margin-left: 0 !important;
  }
}

.reservation-detail {
  margin-bottom: 48px;

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .anticon {
      margin-right: 4px;
      color: #666666;
    }
  }

  .message {
    background-color: #f4f4f4;
    padding: 20px 24px 32px 24px;

    .field-info {
      .field-value {
        font-size: 14px;
        white-space: pre-line;
      }
    }
  }
}

.reservation-item {
  padding: 24px 0;
  border-bottom: 1px solid #d0d0d0;

  .icon {
    margin-right: 16px;
    @include ResponsiveMaxWidth(XS) {
      margin-right: 8px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
    .counselor {
      display: flex;
      align-items: center;

      .label {
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        background-color: #13cbcb;
        border-radius: 8px;
        padding: 2px 8px;
        margin-right: 8px;
        min-width: 88px;
      }

      @include ResponsiveMaxWidth(MD) {
        order: 2;
        padding-bottom: 4px;
      }
    }
  }

  .action {
    //flex-grow: 0;
    flex: auto;
    @include ResponsiveMaxWidth(MD) {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px !important;
    }
    @include ResponsiveMaxWidth(XS) {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      width: 100%;
    }
    @include Responsive(MD) {
      display: flex;
      justify-content: flex-end;
    }
  }

  @include ResponsiveMaxWidth(MD) {
    border-bottom: none !important;
  }
}

.reservation-title {
  @include ResponsiveMaxWidth(MD) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 27px;
  }
}

.reservation-program-name {
  @include ResponsiveMaxWidth(MD) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    order: 1;
    padding-bottom: 4px;
  }
}

.reservation-program {
  flex-wrap: wrap;
  @include ResponsiveMaxWidth(XS) {
    display: block;
  }
}

.reservation-button {
  width: 120px;
  height: 45px;
  justify-content: center;

  &.detail-btn {
    @include ResponsiveMaxWidth(XS) {
      margin-right: 0 !important;
    }
    @include Responsive(XS) {
      max-width: 120px;
    }
  }

  &.consultation-started-btn {
    @include Responsive(XS) {
      max-width: 120px;
    }
    @include ResponsiveMaxWidth(XS) {
      margin-left: 0 !important;
      margin-right: 8px;
    }
  }
}

.reservation-date-time {
  @include ResponsiveMaxWidth(MD) {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 21px;
    color: $color-medium;
    order: 3;
  }
}

.reservation-select-time {
  @include ResponsiveMaxWidth(XS) {
    margin-left: 0 !important;
  }
}

.reservation-select {
  @include ResponsiveMaxWidth(XS) {
    width: 100% !important;
    margin-top: 24px;
  }
}

.reservation-date-picker {
  @include ResponsiveMaxWidth(MD) {
    width: 100%;
  }
}

.reservation-counselor-name {
  @include ResponsiveMaxWidth(MD) {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 21px;
    color: $color-medium;
  }
}

.reservation-form-message {
  @include ResponsiveMaxWidth(MD) {
    margin-bottom: 100px;
  }

  .text-area-description {
    font-size: 14px;
    color: #00000099;
  }
}

.reservation-detail-message {
  @include ResponsiveMaxWidth(XS) {
    margin-bottom: 100px;
  }
}

.reservation-detail-date-time {
  @include ResponsiveMaxWidth(XS) {
    font-weight: bold;
    font-size: 14px !important;
    line-height: 21px;
  }
}

.reservation-detail-typo {
  font-size: 14px;
  @include ResponsiveMaxWidth(XS) {
    margin-bottom: 1em !important;
  }
}

.reservation-item-container {
  @include ResponsiveMaxWidth(MD) {
    margin-top: 0 !important;
  }
}

.custom-zoom-icon span {
  zoom: 0.6;
}

.error-message {
  display: none;
  font-size: 12px;
  color: $error;
}

.has-error {
  .ant-picker,
  .ant-select.tdt-select > .ant-select-selector {
    border-color: $error;
  }

  .error-message {
    display: block;
  }
}

.verify-email-container {
  border: 1px solid $blue-gray-20;
  padding: 16px 16px 16px 48px;
  border-radius: 8px;
  max-width: 730px;
  position: relative;

  .ant-btn-primary {
    min-width: 160px;
    height: 45px;
    justify-content: center;
  }
}

.verify-email-info-icon {
  position: absolute;
  left: 18px;
  top: 18px;
}

.ant-tooltip-inner {
  background-color: $color-medium;
}

.program-detail-float-bar {
  display: flex;
  justify-content: space-between;

  .info-column {
    display: flex;
    flex-direction: row-reverse;
    @include ResponsiveMaxWidth(SM) {
      display: none;
    }
  }

  .program-name-panel {
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    .description {
      font-size: 14px;
      color: $blue-gray-60;
    }
  }
}

.make-rsv-btn {
  width: 232px;
  @include ResponsiveMaxWidth(SM) {
    width: 176px;
  }
}

.reservation-head-page {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flex-row {
  display: flex;
  align-items: center;
  @include ResponsiveMaxWidth(MD) {
    flex-wrap: wrap;
  }
}

.past-reservation-back-title {
  @include ResponsiveMaxWidth(SM) {
    font-weight: 500 !important;
    font-size: 18px !important;
  }
}

.same-day-badge-detail {
  border-radius: 22px !important;
  margin-bottom: 14px;
  max-width: 88px;
  vertical-align: text-top;
}

.mb-mobile-0 {
  @include ResponsiveMaxWidth(SM) {
    margin-bottom: 0;
  }
}

.mobile-flex-wrap {
  @include ResponsiveMaxWidth(SM) {
    flex-wrap: wrap;
  }
}
