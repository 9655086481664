$xs-mobile-width: 320px;
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$big-tablet-width: 1366px;
$big-desktop-width: 1600px;

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin above-mobile {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) and (max-width: #{$big-desktop-width - 1px}) {
        @content;
    }
}

@mixin big-desktop {
    @media (min-width: #{$big-desktop-width}) {
        @content;
    }
}

@mixin ResponsiveMaxWidth($canvas) {
    @if $canvas == SE {
        @media only screen and (max-width: $xs-mobile-width) {
            @content;
        }
    } @else if $canvas == XS {
        @media only screen and (max-width: $mobile-width) {
            @content;
        }
    } @else if $canvas == SM {
        @media only screen and (max-width: $tablet-width) {
            @content;
        }
    } @else if $canvas == MD {
        @media only screen and (max-width: $desktop-width) {
            @content;
        }
    } @else if $canvas == XL {
        @media only screen and (max-width: $big-tablet-width) {
            @content;
        }
    } @else if $canvas == XXL {
        @media only screen and (max-width: $big-desktop-width) {
            @content;
        }
    }
}

@mixin Responsive($canvas) {
    @if $canvas == SE {
        @media only screen and (min-width: $xs-mobile-width) {
            @content;
        }
    } @else if $canvas == XS {
        @media only screen and (min-width: $mobile-width) {
            @content;
        }
    } @else if $canvas == SM {
        @media only screen and (min-width: $tablet-width) {
            @content;
        }
    } @else if $canvas == MD {
        @media only screen and (min-width: $desktop-width) {
            @content;
        }
    } @else if $canvas == XL {
        @media only screen and (min-width: $big-tablet-width) {
            @content;
        }
    } @else if $canvas == XXL {
        @media only screen and (min-width: $big-desktop-width) {
            @content;
        }
    }
}

@mixin ResponsiveTablet($canvas) {
    @if $canvas == Ipad {
        @media only screen and (min-device-width: $tablet-width) and (max-device-width: $desktop-width) and (-webkit-min-device-pixel-ratio: 1) {
            @content;
        }
    } @else if $canvas == IpadPro {
        @media only screen and (min-device-width: $desktop-width) and (max-device-width: $big-tablet-width) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    }
}
