.label-title {
    font-size: 16px;
    font-weight: bold;
}
.datePicker {
    height: 40px;
    background-color: #f4f4f4;
}

.logs {
    width: 320px !important;
}

.logs-date {
    border: 1px solid #d1d0d6;
    background-color: #f4f4f4;
    border-radius: 4px;
    box-sizing: border-box;
}

.logs-date-picker {
    background-color: #f4f4f4;
    border: 1px solid #d1d0d6;
}

.logs-table .collapse-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 12px 8px !important;
}
.page-size-logs {
    padding-top: 15px;
}

.logs .anticon-swap-right svg {
    display: none;
}
.logs .ant-picker-separator::before {
    content: '~';
    color: black;
}
