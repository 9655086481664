@import '../abstract/variables';

.custom-quill-editor {
    position: relative;
}

.ql-container.ql-snow {
    border-radius: 4px;
    border: 1px solid $blue-gray-20;
    background: #fff;
}

.ql-editor {
    padding: 8px 16px;
    min-height: 256px;
}

.quill {
    position: relative;
}

.ql-toolbar.ql-snow {
    position: absolute;
    z-index: 9999;
    height: 45px;
    top: -70px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 82px;
    border-color: transparent;
    display: flex;
    justify-content: center;

    svg {
        color: $blue-purple-100;
    }

    button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid transparent;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2px;
    }

    .ql-active {
        border-color: $blue-gray-30;
        background-color: $blue-purple-10;
    }
}

.ql-snow .ql-color-picker {
    width: 32px;
    height: 32px;
    padding: 4px;

    .ql-picker-label {
        background-color: $blue-gray-100;
        border-radius: 4px;
        border-color: transparent;

        &[data-value='red'] {
            background-color: #EC4739;
        }
        &[data-value='green'] {
            background-color: #3A8025;
        }
        &[data-value='blue'] {
            background-color: #0808F6;
        }
        &[data-value='orange'] {
            background-color: #F4B454;
        }
        &[data-value='violet'] {
            background-color: #E291E9;
        }
        &[data-value='#D5D6D7'] {
            background-color: #D5D6D7;
        }
    }
}

.ql-container {
    font: inherit;
    font-size: 14px;
    line-height: 20px;
}

.ql-snow a {
    color: #E63E55;
}