@import '../abstract/variables';

.ant-form-item-label {
    font-weight: bold;
}
.ant-form-item-extra,
.ant-form-item-explain {
    font-size: 12px;
}
.ant-form-item-extra {
    margin-top: 4px;
}
.vertical-group {
    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }

    &.baseline-align-checkboxes {
        .ant-radio-wrapper,
        .ant-checkbox-wrapper {
            align-items: baseline;
        }
    }
}

.custom-form {
    .form-item {
        margin-bottom: 24px;
        label {
            display: block;
            margin-bottom: 8px;
            font-weight: bold;
        }
        .form-item-extra {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
        }
    }
}

.has-error-item {
    .ant-picker, .ant-input-number, .ant-input {
        border-color: $red-100;
    }
}

.period-has-error-item {
    .ant-picker, .ant-input-number {
        border-color: $red-100;
    }
}

.period-error-text {
    color: $red-100;
}