@import '../abstract/variables';

.daily-life-events {

  .daily-life-wrapper {
    display: flex;
  }

  .daily-life-times {

  }

  .daily-life-calendar {
    width: 286px;
    position: relative;
  }

  .daily-life-actions {
    flex: 1;
    position: relative;
  }

  .dle-time {
    width: 30px;
    margin-right: 4px;
    margin-left: 16px;
    display: flex;
    margin-bottom: 72px;
    height: 0;

    span {
      font-size: 12px;
      line-height: 18px;
      color: $blue-gray-100;
    }
  }

  .dle-hor-line {
    //margin-bottom: 72px;
    padding-bottom: 72px;
    height: 0;

    &:focus, &:active {
      background-color: $blue-gray-5;
      transition: background-color 0.1s ease-in-out;
    }

    span {
      border-top: 1px solid $blue-gray-20;
      width: 100%;
      display: block;
    }

    &:last-child {
      pointer-events: none;
    }
  }

  .dle-event-slot {
    position: absolute;
    border-radius: 8px;
    left: 8px;
    right: 8px;
    //height: 72px;
    //top: 0;
    cursor: pointer;

    &.slot-creating {
      //border: 3px solid $blue-purple-100;
      border-width: 3px;
      border-style: solid;
      background: $blue-purple-10;
    }

    &.slot-filled {
      //border: 3px solid #0088FF;
      //background: #4284F3;
    }

    .dle-type-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #222126;
    }

    .dle-three-dot-higher {
      top: -3px;
    }

    &.slot-select {
      background-color: transparentize($blue-gray-10, 0.6);
      //opacity: 0.4;
      border: 2px dashed transparentize($blue-gray-40, 0.6);
      border-radius: 4px;
      left: 0;
      right: 0;
      cursor: pointer;

      .slot-select-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
    }
  }
}

.dle-as-modal {
  width: 320px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  background-color: #fff;

  &-header {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: $blue-gray-100;
  }

  &-footer {

    button,
    .ant-btn[disabled] {
      border: none;
      box-shadow: none;
      background-color: transparent;
      transition: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-close-icon {
    border: none;
    box-shadow: none;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    outline: none;
  }

  &-dropdown-popup {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }
}

$color-sleep: #9ACCFE;
$color-work: #84A4FC;
$color-teaching_research : #9FE4AC;
$color-study : #FDE791;
$color-meal : #FAB779;
$color-motion : #FFB9D2;
$color-domestic_affairs : #ED9A9A;
$color-rest : #BDA2E9;
$color-leisure : #C1BBB3;

.dle-color {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: $blue-gray-20;

  &-undefined-border {
    border-color: $blue-purple-100;
  }

  &-sleep {
    background-color: $color-sleep;
    &-border {
      border-color: $color-sleep;
    }
  }

  &-work {
    background-color: $color-work;
    &-border {
      border-color: $color-work;
    }
  }

  &-teaching_research {
    background-color: $color-teaching_research;
    &-border {
      border-color: $color-teaching_research;
    }
  }

  &-study {
    background-color: $color-study;
    &-border {
      border-color: $color-study;
    }
  }

  &-meal {
    background-color: $color-meal;
    &-border {
      border-color: $color-meal;
    }
  }

  &-motion {
    background-color: $color-motion;
    &-border {
      border-color: $color-motion;
    }
  }

  &-domestic_affairs {
    background-color: $color-domestic_affairs;
    &-border {
      border-color: $color-domestic_affairs;
    }
  }

  &-rest {
    background-color: $color-rest;
    &-border {
      border-color: $color-rest;
    }
  }

  &-leisure {
    background-color: $color-leisure;
    &-border {
      border-color: $color-leisure;
    }
  }
}

.dle-add-event-wrapper {
  position: absolute;
}

.dle-three-dots {
  position: absolute;
  right: 8px;
  border: none;
  box-shadow: none;
  width: unset;
  padding: 0;
  background-color: transparent !important;
  color: #222126;
  top: 8px;
  height: 24px;

  &:hover {
    text-decoration: underline;
  }

  circle {
    fill: #222126;
  }
}

.result-daily-life {

  &-wrapper {
    margin-top: 16px;
    display: flex;
  }

  &-slots {
    width: 132px;
    position: relative;
  }

  .rdl-placeholder {
    margin-bottom: 24px;
    height: 0;
  }

  .rdl-time {
    width: 35px;
    margin-right: 4px;
    margin-left: 16px;
    display: flex;
    margin-bottom: 24px;
    height: 0;

    span {
      font-size: 12px;
      line-height: 1px;
      color: $blue-gray-100;
    }
  }

  .rdl-slot {
    position: absolute;
    border-radius: 4px;
    left: 0;
    right: 0;
    border-top: 1px solid $blue-gray-20;
  }
}

.dle-event-slot-mobile-overlay {
  display: none;
}

.dle-delete-btn {
  display: none;
}

.dle-slots-select-has-slot {
  display: none;
}

.tdt-pure-select-dropdown {
  left: 0;
  right: 0;
  max-width: unset;
  transition: none !important;
  top: 44px;
  bottom: unset;
  width: 180px !important;

  .ant-select-item {
    &:hover {
      background-color: $blue-gray-5;
    }
  }
}

.parent-ref {

  &, &-plus {
    display: inline-block;

    @include ResponsiveMaxWidth(SM) {
      display: block;
    }
  }
}

.parent-ref-has-max-height {
  position: relative;
}

.parent-ref-has-max-width {
  &-115 {
    .tdt-pure-select-dropdown {
      width: 115px !important;
    }
  }
}

.tdt-pure-select {
  .ant-select-selector {
    background-color: $blue-gray-5 !important;
  }
}

.dropdown-popup-height .rc-virtual-list-holder {
  &.pure-select-max-height {
    &-256 {
      max-height: 256px !important;
    }
  }
}

.daily-life-events-mobile {

  @include ResponsiveMaxWidth(SM) {
    .dle-slots-select-has-slot {
      //display: unset;
    }

    .tdt-pure-select-dropdown {
      top: unset;
      bottom: 44px;
      width: unset !important;
    }

    .dle-add-event-wrapper {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: unset !important;
      z-index: 10000;

      .dle-as-modal {
        width: unset;
        background: $blue-gray-15;
        border: 1px solid $blue-gray-20;
        border-radius: 8px 8px 0 0;
      }
    }

    .ant-select {
      width: 100% !important;
    }

    .dle-time-two-columns {
      > div {
        width: 50%;
      }
    }

    .dle-as-modal-header {
      > div {
        display: none;
      }
      .ant-btn {
        background-color: transparent;
      }

    }

    .delete-action-panel {
      position: absolute;
      top: 12px;
      right: 16px;

      .ant-btn {
        min-width: 80px;
        justify-content: center;
        height: 40px;
      }
    }

    .dle-three-dots {
      display: none;
    }

    .dle-cancel-btn {
      display: none;
    }

    .daily-life-wrapper {
      flex-wrap: wrap;

      .daily-life-calendar {
        flex: 1;
        min-width: 280px;
      }

      .daily-life-actions {
        width: 100%;
        min-width: 280px;

        > button {
          display: none;
        }
      }
    }

    .dle-event-slot-mobile-overlay {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .dle-delete-btn {
      display: unset;
      background-color: #fff;
      border-radius: 32px;
      border: 1px solid $blue-gray-20;

      span {
        color: $blue-purple-100;
      }
    }

    .dle-save-btn {
      background-color: $blue-purple-100;
      border-radius: 32px;
      border: 1px solid $blue-purple-100;

      span {
        color: #fff;
      }

      &[disabled] {
        background-color: $blue-gray-5;
        border-color: $blue-gray-20;

        span {
          color: $blue-gray-30;
        }
      }
    }

    .ant-select.tdt-select > .ant-select-selector,
    .ant-select.tdt-select.select-gray.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff !important;
    }

    .dle-event-slot.slot-select {
      left: 8px;
      right: 8px;
    }
  }
}

.ant-form-item-has-error .daily-life-events .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #d0d0d0 !important;
}