@import '../abstract/variables';

.circle-listed {
    color: #222126;
    font-size: 10px;
    line-height: 15px;
    display: flex;

    .circle-list-item {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $blue-purple-10;
        border: 1px solid #ffffff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        &.item-1 {
            z-index: 5;
        }

        &.item-2 {
            margin-left: -8px;
            z-index: 4;
        }

        &.item-3 {
            margin-left: -8px;
            z-index: 3;
        }

        &.item-4 {
            margin-left: -8px;
            z-index: 2;
        }

        &.item-5 {
            background-color: #e8e8eb;
            margin-left: -8px;
            z-index: 1;
        }
    }
}
