@import '../abstract/mixin';

#container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 24px 104px 24px;
    position: relative;
    margin-left: 220px;

    .tdt-content {
        padding: 0px 24px 24px 24px;
        @include ResponsiveMaxWidth(MD) {
            margin-top: 54px;
        }
    }
    @include ResponsiveMaxWidth(MD) {
        margin: 0;
        padding: 0;

        .tdt-content {
            padding: 16px;
        }
    }
    @include ResponsiveTablet(IpadPro) {
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 24px 104px 24px;
        position: relative;
        margin-left: 220px;
    }
}

#container-none-user {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 24px 104px 24px;
    position: relative;
    margin-left: 220px;

    .tdt-content {
        padding: 0px 24px 24px 24px;
    }

    .float-bar {
        left: 220px;
    }
}

.self-layout {
    #container-none-user {
        padding: unset;
        min-height: 100vh;

        .tdt-content {
            padding: unset;
        }
    }
}

.self-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 24px 104px;
    min-height: 100vh;
}