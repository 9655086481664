@import '../../abstract/variables';
@import '../../abstract/mixin';

.controls {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-controls {
        display: flex;
        align-items: center;
    }

    .arrow-btn {
        border: none;
        color: #767384;
        height: 32px;
        width: auto;
    }

    .today-btn {
        height: 32px;
        border-radius: 8px;
        border: 1px solid $blue-gray-20;
        padding: 6px 10px;
        font-size: 14px;
    }

    .layout-btn {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        padding: 0;
        justify-content: center;
    }

    .type-dropdown {
        height: 32px;
        padding: 0 4px;
        border-radius: 4px;
    }
}
