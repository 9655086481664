@import '../abstract/variables';
@import '../abstract/mixin';

$processing-blue: #0084ff;
$done-blue: #0085ff;

$week-header-height: 8px;

.tdt-calendar {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    table-layout: fixed;
    border-collapse: collapse;

    tbody {
        tr {
            td {
                &.blocked {
                    background-color: #e5e5e5;
                }

                &.disabled {
                    background-color: #f4f4f4;
                }

                &.active:hover {
                    background-color: #f0ebf7;
                }

                .dropdown-clickable {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.tdt-calendar-day {
        thead {
            tr {
                th {
                    position: relative;
                    &.time {
                        width: 50px;
                    }

                    .event-wrapper {
                        top: 2px;

                        .event-row {
                            position: absolute;
                            display: flex;
                            width: 100%;
                            padding: 4px;
                            overflow-x: hidden;

                            .event {
                                position: initial;
                                display: inline-flex;
                                margin-right: 4px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border: 1px solid $gray-separator;
                    padding: 4px;
                    height: 96px;
                    vertical-align: top;
                    &.time {
                        border: none;
                        text-align: right;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    &.tdt-calendar-week {
        thead {
            tr {
                th {
                    position: relative;
                    font-weight: 400;
                    padding: 4px 0;
                    text-align: center;
                    height: $week-header-height;
                    width: 100%;

                    &.time {
                        width: 50px;
                    }

                    &:first-child {
                        color: #ff0000;
                    }
                    &:last-child {
                        color: $done-blue;
                    }

                    .week-day {
                        font-size: 12px;
                        margin-bottom: 4px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid $gray-separator;
                    padding: 4px;
                    height: 96px;
                    vertical-align: top;
                    &.time {
                        border: none;
                        text-align: right;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    &.tdt-calendar-month {
        thead {
            tr {
                th {
                    font-weight: 400;
                    padding: 8px 0;
                    text-align: center;
                    width: 14.256%;

                    &:first-child {
                        color: #ff0000;
                    }
                    &:last-child {
                        color: $done-blue;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid $gray-separator;
                    padding: 8px;
                    height: 121px;
                    vertical-align: top;

                    .date {
                        width: 24px;
                        height: 24px;
                        text-align: center;
                        margin-left: -4px;
                    }

                    &.current {
                        .date {
                            border-radius: 50%;
                            background-color: $secondary;
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        .events {
            margin-top: 6px;
            padding-right: 20px;
        }

        .show-more {
            color: #4c18ed;
            opacity: 0.4;
            text-decoration: underline;
            cursor: pointer;
            font-size: 13px;
        }
    }
}

.event {
    padding: 2px 8px;
    border-radius: 4px;
    color: #ffffff;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.processing {
        background-color: $processing-blue;
    }
    &.done {
        background-color: #99ceff;
    }
    &.cancelled {
        background-color: #999999;
    }
    .time {
        font-size: 11px;
        margin-right: 4px;
    }
    .title {
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.event-wrapper {
    position: absolute;
    pointer-events: none;
    height: 2304px;
    top: $week-header-height;
    overflow: hidden;
    left: 0;
    right: 3px;

    .event {
        pointer-events: auto;
        position: absolute;
        left: 4px;
        right: 20px;
        text-align: left;
        align-items: flex-start;
        border: 1px solid #ffffff;
        flex-direction: column;
        cursor: pointer;

        .subtitle {
            width: 100%;
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .title {
            width: 100%;
        }
    }
}

.calendar-detail-modal {
    .event-title {
        font-size: 16px;
        font-weight: normal;
    }
    .event-subtitle {
        font-size: 12px;
        font-weight: 500;
        color: #767384;
    }
}

.calendar-more-modal {
    .event {
        padding: 8px;
        cursor: pointer;
    }
    .event-title {
        font-size: 14px;
        font-weight: normal;
        color: $blue-gray-100;
    }
    .event-subtitle {
        font-size: 12px;
        color: $color-medium;
    }
}

.calendar-week-header {
    display: flex;
    .time {
        flex: 50px 0 0;
    }
    .column {
        flex: 1;
        text-align: center;

        .date {
            display: inline-block;
            font-size: 16px;
            line-height: 32px;
            width: 32px;
            height: 32px;
            text-align: center;
            color: $blue-gray-100;

            &.current {
                border-radius: 50%;
                background-color: $secondary;
                color: #ffffff;
            }
        }
    }
}

.tdt-calendar-week-wrapper {
    margin-bottom: -100px;
    height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
}

.tdt-calendar-day-wrapper {
    margin-bottom: -100px;
    height: calc(100vh - 203px);
    overflow-y: auto;
    overflow-x: hidden;
}

.calendar-cell-deleted {
    background-color: $blue-gray-50 !important;
    opacity: 0.4;
}
