@import '../abstract/variables';
@import '../abstract/mixin';

.icon-btn {
    @include ResponsiveMaxWidth(MD) {
        width: 24px !important;
        height: 24px !important;
    }
}

.icon-in-row {
    @include ResponsiveMaxWidth(MD) {
        width: 32px;
        height: 32px;
    }
}
.custom-icon:hover {
    color: $blue-purple-80;
}
