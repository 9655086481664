@import '../abstract/variables';
@import '../abstract/mixin';

.description-no-data {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black-high;
}

.health-data-row {
    &.data-details {
        height: 72px;
        border-bottom: 1px solid $outline !important;
    }
    &.in-modal {
        height: 64px;
    }
    height: 126px;
    width: 100%;
    border-bottom: 1px solid $outline;

    @include ResponsiveMaxWidth(MD) {
        height: 154px;
        border-bottom: none;
    }
}

.health-data-col-center {
    display: flex;
    align-items: center;
    @include ResponsiveMaxWidth(XS) {
        display: block;
    }
}

.health-data-col-in-modal {
    display: flex;
    align-items: center;
}

.health-data-col-flex-end {
    display: flex;
    justify-content: flex-end;
    @include ResponsiveMaxWidth(MD) {
        flex-direction: row-reverse;
        justify-content: space-evenly;
    }
}
.health-data-col-switch-check {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.health-data-numbers {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 100;
    font-size: 48px;
    line-height: 48px;
    color: $black-high;
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: 100;
        font-size: 32px;
        line-height: 46px;
        color: $black-high;
    }
}

.health-data-input-btn {
    width: 120px;
    height: 45px;
    display: flex;
    justify-content: center;
    &.input-btn {
        @include ResponsiveMaxWidth(MD) {
            margin-right: 4px;
        }
    }
}

.health-data-history-btn {
    width: 120px;
    height: 45px;
    display: flex;
    justify-content: center;
    @include ResponsiveMaxWidth(MD) {
        margin-right: 0 !important;
        margin-left: 4px;
    }
}

.health-data-delete-span {
    @include ResponsiveMaxWidth(XS) {
        display: none !important;
    }
}

.health-data-page-title {
    font-size: 24px;
    font-weight: bold;
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0 !important;
    }
}

.health-data-head-row {
    margin-top: 40px;
    margin-bottom: 16px;
    @include ResponsiveMaxWidth(MD) {
        margin-top: 0;
    }
}

.text-on-surface-high {
    color: $black-high;
    @include ResponsiveMaxWidth(XS) {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $color-medium;
        padding: 0 !important;
    }
}

.pressure-text-validation {
    font-size: 14px;
    line-height: 20px;
    color: $red-100;
    padding-top: 16px;
}

.modal-delete-btn {
    width: 144px;
    height: 48px;
    justify-content: center;
    @include ResponsiveMaxWidth(XS) {
        width: 34px;
        height: 34px;
        justify-content: center;
        border-radius: 4px !important;
        //padding-left: 6px;
    }
}

.icon-label {
    color: $black-high;
    @include ResponsiveMaxWidth(MD) {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        padding-left: 12px !important;
    }
}

.health-data-value-pc {
    @include ResponsiveMaxWidth(MD) {
        display: none;
    }
}

.health-data-value-none-pc {
    @include Responsive(MD) {
        display: none;
    }
    @include ResponsiveTablet(IpadPro) {
        display: inherit;
    }
    @include Responsive(XS) {
        padding-left: 40px;
    }
}

.heath-data-modal-input {
    &.weight {
        width: 260px;
        height: 40px;
        @include ResponsiveMaxWidth(SE) {
            width: 240px;
        }
    }
    &.heart-beat {
        width: 240px;
        height: 40px;
        @include ResponsiveMaxWidth(SE) {
            width: 222px;
        }
    }
    &.pressure {
        width: 104px;
        height: 40px;
        @include ResponsiveMaxWidth(SE) {
            width: 94px;
        }
    }
}

.health-data-list-images {
    @include ResponsiveMaxWidth(MD) {
        width: 48px !important;
        height: 48px !important;
        margin-right: 6px !important;
    }
}

.health-data-chart {
    @include ResponsiveMaxWidth(XS) {
        width: 800px;
    }
}

.edit-btn-pc {
    @include ResponsiveMaxWidth(MD) {
        display: none;
    }
}
.edit-btn-mb {
    @include Responsive(MD) {
        display: none;
    }
    @include ResponsiveTablet(IpadPro) {
        display: inherit;
    }
}

.edit-list-btn {
    @include ResponsiveMaxWidth(MD) {
        width: 152px !important;
    }
}

.image-modal-label {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
}

.image-modal-select {
    width: 288px;
    @include ResponsiveMaxWidth(SE) {
        width: 272px;
    }
}

.photo-tab-container {
    margin: 32px 32px 62px 32px;
}
.photo-range-picker-wrapper .ant-picker-separator::before {
    content: '~';
    color: black;
}
.photo-range-picker-wrapper .anticon-swap-right svg {
    display: none;
}
.btn-slide-photo {
    border: 1px solid $color-outline !important;
    border-radius: 4px;
    justify-content: center;
    width: 38px;
    height: 38px;
    @include ResponsiveMaxWidth(XS) {
        width: 34px;
        height: 34px;
    }
}

.flex {
    display: flex;
    &.center {
        justify-content: center;
    }
    &.align-end {
        align-items: end;
    }
}

.graph-tool-tip {
    width: 100px;
    height: 58px;
    &.pressure {
        width: 165px;
    }
}

.graph-span {
    font-family: Noto Sans JP;
    font-size: 12px;
    line-height: 18px;
    color: $black-high;

    &.value {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

.footer-photo-modal {
    @include ResponsiveMaxWidth(XS) {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.chart-today-button {
    border: 1px solid $blue-gray-20;
    box-sizing: border-box;
    border-radius: 8px;
    width: 46px;
    height: 29px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: $primary;
    justify-content: center;
}

.chart-container {
    @include ResponsiveMaxWidth(MD) {
        margin-top: 12px !important;
    }
}

.chart-date-text {
    @include ResponsiveMaxWidth(XS) {
        display: none;
    }
}

.chart-wrapper {
    @include ResponsiveMaxWidth(XS) {
        overflow-y: auto;
        width: 100%;
    }
}

.chart-month-picker {
    @include ResponsiveMaxWidth(XS) {
        width: 150px;
    }
}

.btn-delete-health-data {
    border: none !important;
}

.select-date-group-btn {
    border-radius: 6px;
    box-sizing: border-box;
}
.ant-radio-button-wrapper:first-child {
    border: 1px solid $primary;
    border-radius: 8px 0px 0px 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0px 8px 8px 0px;
}

.add-file-btn {
    border: 1px solid $blue-gray-20 !important;
}

.select-date-btn {
    border-color: $primary;
    color: $primary;
    font-weight: bold;
    box-sizing: border-box;
    width: 80px;
    height: 32px;
    text-align: center;
    @include ResponsiveMaxWidth(MD) {
        width: 52px;
    }
}

.ant-upload.ant-upload-select-picture-card {
    border: none;
    width: 100%;
    height: fit-content;
    background: none;
}

.ant-upload-list-picture-card-container {
    width: 64px;
    height: 64px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
    padding: 0;
    width: 64px;
    height: 64px;
}

.ant-upload-list-item-name {
    display: none !important;
}

.ant-modal-content .photo-tab-modal {
    padding: 0 16px;
    @include ResponsiveMaxWidth(XS) {
        padding: 0 6px;
    }
}
.photo-tab-modal {
    &.center {
        text-align: center;
    }
    &.fs-12-none-pc {
        @include ResponsiveMaxWidth(XS) {
            font-size: 12px;
        }
    }
}
.photo-range-picker {
    width: 268px;
    background: $blue-gray-5;
}

.pc-display {
    @include ResponsiveMaxWidth(XS) {
        display: none !important;
    }
}

.none-pc-display {
    @include Responsive(XS) {
        display: none;
    }
}

.ant-modal-footer {
    &.none-border {
        border-top: none;
    }
}
.ant-picker-panels {
    @include ResponsiveMaxWidth(MD) {
        flex-direction: column;
    }
}
.data-date-text {
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }
}
.data-title {
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
}
.ant-row health-data-row data-details:last-child {
    @include ResponsiveMaxWidth(MD) {
        margin-bottom: 100px !important;
    }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
    a {
        display: none;
    }
}
