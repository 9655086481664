.g-hover {
    .hover-point,
    .hover-line,
    .tooltip {
        display: none;
    }
    &:hover {
        .hover-point,
        .hover-line,
        .tooltip {
            display: initial;
        }
    }
}

.svg-chart {
    text {
        //font: 3px sans-serif;
        text-anchor: middle;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 700;
        font-size: 2px;
    }
}

.graph-h-300 {
    @include ResponsiveMaxWidth(SM) {
        min-height: 300px !important;
    }
}