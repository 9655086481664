@import '../../abstract/variables';

$ellipse-blue: #0084ff;
$ellipse-orange: #f64f00;

.ellipse-as-status {
    width: 8px;
    height: 8px;
    border-radius: 100%;

    &.bg-blue {
        background-color: $ellipse-blue;
    }

    &.bg-orange {
        background-color: $ellipse-orange;
    }
}

// modal: search + filters
.modal-search-filters {
}

.modal-search-result {
    padding-top: 16px;
    max-height: 532px;
    overflow-y: auto;
}

.counselor-message-create-container
    .ant-select.tdt-select.select-gray.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 66px;
}

.counselor-feedback-create-container
    .ant-select.tdt-select.select-gray.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 66px;
}

.counselor-message-create-container .ant-select.tdt-select.select-gray .ant-select-arrow {
    position: absolute;
    top: 28px;
}

.counselor-feedback-create-container .ant-select.tdt-select.select-gray .ant-select-arrow {
    position: absolute;
    top: 28px;
}

.modal-search-result-row {
    border-bottom: 1px solid $blue-gray-20;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: $blue-gray-5;
    }

    &.selected {
        background-color: $blue-gray-5;
    }
}
