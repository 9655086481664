@import '../abstract/variables';

.empty-filter-icon {
    color: $color-medium;

    svg path {
        fill: none;
    }
}

.filter-container {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.filter-container-rows {
    padding-bottom: 4px;
}

.filter-container-row {
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    height: 64px;
    min-width: 313px;
    position: relative;
    justify-content: space-between;

    &:after {
        background-color: #ddd;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    .btn-choose {
        border-radius: 4px;
        border: 1px solid $blue-gray-20;
        padding: 5px 8px;
        cursor: pointer;
        background-color: $blue-gray-5;
        min-width: 108px;

        &[disabled] {
            cursor: default;
            background: $bg-gray;
            border-color: $bg-gray;
            color: $color-disabled;

            .btn-choose-dropdown {
                color: $color-disabled;
            }
        }
    }

    .btn-choose-dropdown {
        color: $color-medium;
        margin-left: auto;
    }
}

.filter-container-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.filter-render-tags {
    font-size: 14px;
    line-height: 100%;
    text-align: left;

    .filter-tag {
        background: #a578e5;
        border-radius: 24px;
        color: #fff;
        padding: 6px 12px;
        margin-right: 4px;
        margin-bottom: 8px;
        display: inline-flex;
        align-items: center;

        .svg-icon {
            cursor: pointer;
        }
    }
}

.status-arrangement {
    .ant-dropdown-menu {
        padding: 8px 0;
    }

    .ant-dropdown-menu-item {
        height: 48px;

        &:after {
            display: none;
        }
    }
}

.ant-dropdown-menu {
    padding: 16px 0;

    .ant-dropdown-menu-item {
        height: 39px;

        &:after {
            display: none;
        }
    }
}

.calendar-dropdown-range-type {
    max-width: 232px;

    .ant-dropdown-menu {
        min-width: 232px;
    }
}
