@import 'layouts/main';
@import 'layouts/sidebar';
@import 'layouts/container';

@import 'abstract/utilities';
@import 'abstract/typography';

@import 'components/avatar';
@import 'components/button';
@import 'components/calendar';
@import 'components/chart';
@import 'components/checkbox';
@import 'components/date_picker';
@import 'components/dropdown';
@import 'components/drop_file_input';
@import 'components/field_info';
@import 'components/floatbar';
@import 'components/form';
@import 'components/icon';
@import 'components/input';
@import 'components/message';
@import 'components/modal';
@import 'components/question';
@import 'components/radio';
@import 'components/select';
@import 'components/text';
@import 'components/table';
@import 'components/tab_list';
@import 'components/tree_searchable';
@import 'components/filter';
@import 'components/page_elements';
@import 'components/circle';
@import 'components/switch';
@import 'components/daily_life_events';

@import 'pages/index';

@import 'pages/admin/interview_sheet';
@import 'pages/admin/calendar';
@import 'pages/admin/company';
@import 'pages/admin/seminar';

@import 'pages/client/reservation';
@import 'pages/client/registration';
@import 'pages/client/settings';
@import 'pages/client/inquiry';

@import '~react-quill/dist/quill.snow.css';
@import 'components/text_editor';
@import 'components/video_theme';