@import '../abstract/variables';

.ant-switch-handle {
  width: 20px;
  height: 20px;
  top: -3px;
  left: 0;

  &:before {
    background-color: $blue-purple-100;
    border-radius: 100%;
  }
}

.ant-switch {
  min-width: 34px;
  height: 14px;

  &-checked {
    background-color: #bba7f8;
  }
}