@import '../abstract/variables';

.ant-table {
    .text-right {
        text-align: right;
    }
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td {
    background-color: #ffffff;
    border-bottom: none;
    padding: 17px 8px;
}
.ant-table.ant-table-middle .ant-table-tbody > tr:first-child > td {
    padding-top: 25px;
}
.ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 8px;
    background-color: #f4f4f4;
    border: 1px solid #fff;
}

.collapse-table {
    .ant-table-thead > tr > th {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: $color-high;
    }

    .ant-table-container table > thead > tr:first-child th:first-child,
    .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
        border-left: 0;
        border-right: 0;
        border-top-color: $color-disabled;
        border-bottom-color: $color-outline;
    }

    table {
        border-collapse: collapse;
        font-size: 14px;
        line-height: 21px;
    }

    .ant-table-tbody {
        border-bottom: 1px solid $color-disabled;

        tr {
            border-top: 1px solid $color-outline;
        }
    }

    &.table-border-lighter {
        .ant-table-tbody {
            border-bottom: 1px solid $blue-gray-10;
        }
        .ant-table-thead > tr > th {
            border-top-color: transparent $blue-gray-10;
        }
    }

    .ant-table.ant-table-middle .ant-table-tbody > tr:first-child > td {
        padding-top: 12px;
    }

    .ant-table.ant-table-middle .ant-table-tbody > tr > td {
        padding: 12px 8px;

        &.action-with-icon {
            padding: 0 8px;
        }
    }

    // no data => no border
    .ant-table-placeholder {
        border-bottom: 1px solid transparent;
    }

    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        font-size: 16px;
        line-height: 24px;
        color: $color-high;
    }

    .ant-table-pagination-right {
        position: absolute;
        right: 0;
        top: -41px;
        margin: 0;
    }
}

.collapse-table .ant-table-tbody tr {
    border-top: none;
}

.ant-table-thead > tr > th {
    background: $blue-gray-10 !important;
}

.ant-table-tbody > tr:nth-child(even) > td {
    background: $blue-gray-5 !important;
}

//.ant-table-tbody > tr.ant-table-row {
//    pointer-events: none;
//}
//
//.ant-table-tbody > tr.ant-table-row:hover > td {
//    background: $blue-purple-10;
//}
//
//.ant-table-tbody > tr.ant-table-row {
//    transform: scale(1);
//}
//.ant-table-tbody > tr.ant-table-row > td {
//    position: static;
//    padding: 0 !important;
//    background-color: unset !important;
//}
//.ant-table-tbody > tr.ant-table-row > td span {
//    display: block;
//    width: 100%;
//    padding: 12px 8px;
//}
//.ant-table-tbody > tr.ant-table-row > td:first-child span:after {
//    content: '';担当企業 / プログラム
//    display: none;
//    position: absolute;
//    left: 0;
//    top: 0;
//    height: 100%;
//    width: 100%;
//    z-index: -1;
//}
//.ant-table-tbody > tr.ant-table-row > td:first-child:hover span:after {
//    display: block;
//    background: $blue-purple-10;
//}

//
.ant-table-tbody > tr.ant-table-row {
    &:hover > td {
        background-color: $blue-purple-10 !important;
        cursor: pointer;
    }

    &.row-cursor-default > td {
        cursor: default !important;
    }
}

.no-clickable-row-table .ant-table-tbody > tr.ant-table-row {
    &:hover > td, & > td {
        cursor: default;
    }
}

.with-cell-medium {
    &.collapse-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
        padding: 16px 8px;
    }
}

.table-info-selected-items {
    position: absolute;
    bottom: 56px;
    border-radius: 50px;
    background-color: #fff;
    border: 1px solid $blue-gray-5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    padding: 8px 32px;
    transform: translate(-50%, -50%);
    left: 50%;
    white-space: nowrap;

    button {
        &, &:hover, &:active {
            height: auto;
            padding: 0;
            color: $blue-purple-100 !important;
            text-decoration: underline !important;
        }
    }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}

.ant-table-column-sorters{
    justify-content: flex-start;
    .ant-table-column-sorter{
        color: $blue-gray-60;
        display: flex;
        width: 24px;
        align-items: center;
        justify-content: center;
    }
}
