@import '../abstract/variables';

.question {
    padding: 40px 0;
    border-bottom: 1px solid $blue-gray-20;
    //&:last-child {
    //    border-bottom: none;
    //}
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 8px;
    }
    .type {
        font-size: 14px;
        color: #666666;
    }
    .required-mark {
        color: $error;
    }
    @include ResponsiveMaxWidth(XS) {
        padding: 10px 0;
    }
}
.label-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    gap: 24px;
    text-align: center;
    white-space: break-spaces;
    /* Blue-Gray-5 */
    background: $blue-gray-5;
    border-radius: 8px;
}

.option-row-check-err {

    &.option-has-row-err {
        padding-bottom: 16px;
    }

    &-name {
        left: 56px;
        bottom: -16px;
    }

    &-score {
        left: 8px;
        bottom: -16px;
        white-space: nowrap;
    }
}