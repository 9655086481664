@import '../../abstract/variables';

.counselor-sidebar {
    padding: 16px 24px;
}

.consultant-name-kana {
    font-size: 12px;
    line-height: 18px;
    color: $blue-gray-60;
}

.consultant-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $blue-gray-100;
}

.consultant-corp-name {
    font-size: 12px;
    line-height: 18px;
    color: $blue-gray-60;
}

// aside
.counselor-aside {
    position: fixed;
    width: 264px;
    height: 100vh;
    background-color: $bg-gray;
    border-right: 1px solid $color-outline;
}

// main
.counselor-main {
    margin-left: 264px;
}

// floatbar
.counselor-floatbar {
    left: 484px;
}

.counselor-health-data-sidebar {
    a {
        display: block;
        padding: 16px;
        color: $color-high;
        position: relative;

        &.active {
            background-color: #fff;
        }

        &:after {
            content: '';
            position: absolute;
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: #c4c4c4;
            bottom: -1px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
