@import '../abstract/variables';

.ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 2px solid $blue-gray-60;
}
.ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
}
.ant-radio-disabled .ant-radio-inner {
    background-color: $blue-gray-10;
    border: 2px solid $blue-gray-40;
}
.ant-radio-disabled + span {
    color: $blue-gray-100;
}
