@import '../abstract/variables';

.ant-input {
  padding: 6.5px 11px;
  background-color: $blue-gray-5;
  font-size: 14px;
  line-height: 22px;

  &::placeholder {
    //color: #8c8c8c;
    color: $blue-gray-30;
    opacity: 1;
  }
}

.ant-input-number-input {
  &::placeholder {
    color: $blue-gray-30;
    opacity: 1;
  }
}

.ant-input-password {
  background-color: $gray-input;
  padding-top: 0px;
  padding-bottom: 0px;

  .ant-input {
    height: 38px;
  }
}

.input-search {
  background-color: $gray-input;
  height: 32px;

  .anticon {
    color: $color-medium;
  }
}

.input-search-distribution_list {
  .ant-input {
    background-color: $white;
  }
}

// Text area length counter
.ant-input-textarea-show-count::after {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
}

.ant-input-textarea-show-count {
  &.has-error {
    textarea.ant-input {
      border-color: $error;
    }
  }
}

// TdtInputSearch
.tdt-input-search {
  .ant-input {
    font-size: 14px;
    line-height: 21px;
    padding: 4px 12px 5px 40px;
    border-radius: 4px !important;
  }

  .ant-input-group-addon {
    position: absolute;
    // left: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 32px;

    .ant-input-search-button {
      height: 24px;
      background-color: transparent;
      border-color: transparent;
      color: $color-medium;
      box-shadow: none;
      z-index: 2;
      width: 24px;
      padding: 0;
      border-radius: 4px !important;
    }
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 8px;
    font-size: 14px;
    line-height: 21px;
  }
}

.ant-form-item-extra {
  &.ant-form-item-extra-error {
    color: $error;
  }
}

.ant-form-item-explain {
  &.ant-form-item-explain-error {
    color: $error;
  }
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: $error;
}

.tdt-custom-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ant-input-affix-wrapper {
  background: $blue-gray-5;
}

.text-area-show-count {
  display: block;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
}

.input-number-no-handler-wrap {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.input-number-score {
  .ant-input-number-input {
    padding: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.input-number-middle {
  .ant-input-number-input {
    padding: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}


.free-input-number {
  background-color: $blue-gray-5;
  border-color: $blue-gray-20;
  border-radius: 4px;

  .ant-input-number-input {
    padding: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.input-number-dnd {
  input {
    height: 38px;
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.interview-sheet-input-create {
  font-size: 16px;
  line-height: 24px;
}

.border-red {
  border-color: $error;
}
