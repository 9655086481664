@import '../abstract/variables';

.field-info {
    .field-label {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 8px;

        .edit-icon {
            margin-left: 8px;
            color: $primary;
            cursor: pointer;
        }
    }
    .field-value {
        font-size: 20px;
    }
}
