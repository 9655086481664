@import '../abstract/variables';
@import '../abstract/mixin';

.message-with-icon {
  margin-top: 8px;
  text-align: center;

  h1 {
    font-size: 34px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 16px;
    @include ResponsiveMaxWidth(XS) {
      font-size: 21px;
    }
  }
}

.custom-notification {
  background: $color-high;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  color: $white;

  .ant-notification-notice-close {
    color: $white;
  }

  .ant-notification-notice-message,
  .ant-notification-notice-description {
    margin: 0;
    color: $white;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .ant-notification-notice-message {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .ant-notification-notice-description {
    margin-right: 32px;
  }

  .btn-notification-action {
    width: max-content;
    height: 37px;
    background: none;
    border: 1px solid $blue-gray-20;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 8px 20px;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  @include ResponsiveMaxWidth(XS) {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    max-width: 100%;
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: 0.5s linear forwards;
    animation: 0.5s linear forwards;
    animation-duration: 0.5s;

    @keyframes NotificationFadeIn {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes NotificationLeftFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes NotificationFadeOut {
      0% {
        opacity: 1;
      }
      25% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.custom-notification-mobile,
.custom-notification-tablet {
  @extend .custom-notification;
}

.custom-notification-mobile {
  @include ResponsiveMaxWidth(XS) {
    top: 40px;
    z-index: 99999999;
  }
}

.custom-notification-tablet {
  @include ResponsiveMaxWidth(MD) {
    top: 50px;
    z-index: 99999999;
  }
}