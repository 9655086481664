@import '../abstract/variables';

.ant-picker {
    padding: 6.5px 11px 6.5px;
    .anticon {
        color: #666666;
    }
}

.tdt-datepicker-panel {
    .ant-picker-panel {
        font-size: 14px;
        //width: 320px;

        .ant-picker-date-panel {
            width: 320px;
        }

        .ant-picker-year-panel {
            width: 320px;
        }

        .ant-picker-month-panel {
            width: 320px;
        }

        .ant-picker-decade-panel {
            width: 320px;
        }

        .ant-picker-header {
            padding: 25px 20px 16px 20px;
            align-items: center;
            border-bottom: none;
            border-top: none;

            .ant-picker-header-super-prev-btn,
            .ant-picker-header-super-next-btn {
                display: none;
            }

            .ant-picker-header-prev-btn,
            .ant-picker-header-next-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                color: $blue-gray-60;
            }

            .ant-picker-header-view {
                font-size: 14px;
                font-weight: normal;
                line-height: normal;
                flex: none;

                button {
                    line-height: 29px;
                }
            }
        }

        .ant-picker-body {
            padding: 0 20px 30px 20px;
            .ant-picker-content {
                width: 280px;

                thead {
                    display: none;
                }

                th {
                    width: 40px;
                    height: 40px;
                }

                td {
                    border: 1px solid #d0d0d0;
                    background-color: #f0ebf7;
                    color: $primary;
                    width: 40px;
                    height: 40px;
                    font-weight: bold;

                    &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
                        .ant-picker-cell-inner {
                        background-color: transparent;
                    }

                    &.ant-picker-cell-today {
                        background-color: $primary !important;
                        color: #ffffff !important;

                        .ant-picker-cell-inner:before {
                            border: none;
                        }
                    }

                    &.ant-picker-cell-selected {
                        .ant-picker-cell-inner {
                            border-radius: 50%;
                            width: 32px;
                            height: 32px;
                            background-color: $blue-purple-10;
                            color: $primary;
                            border: 1px solid $primary;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    &.ant-picker-cell-selected.ant-picker-cell-today {
                        .ant-picker-cell-inner {
                            color: #ffffff !important;
                            //border: none !important;
                            //border-radius: unset !important;
                            background: $primary;
                            border-color: #fff;
                        }
                    }

                    &.ant-picker-cell-disabled {
                        background-color: transparent;

                        &:before {
                            background-color: transparent;
                        }

                        .ant-picker-cell-inner {
                            color: $blue-gray-60;
                        }
                    }
                }
            }
        }

        .ant-picker-footer {
            position: absolute;
            top: 25px;
            right: 20px;
            min-width: auto;
            border-top: none;

            .ant-picker-today-btn {
                font-size: 14px;
                font-weight: bold;
                border: 1px solid #d0d0d0;
                border-radius: 32px;
                width: 44px;
                height: 29px;
                line-height: 29px;
                display: block;
                transition: all 0.3s;

                &:hover {
                    border-color: $primary;
                }
            }
        }
    }
}

.tdt-datepicker-panel
    .ant-picker-panel
    .ant-picker-body
    .ant-picker-content
    td.ant-picker-cell-disabled.ant-picker-cell-today
    .ant-picker-cell-inner {
    color: $white !important;
}

.period-filter-range {
    position: relative;

    .ant-picker-input > input {
        max-width: 90px;
    }

    .whole-period {
        position: absolute;
        white-space: nowrap;
        top: 8px;
        left: 16px;
        pointer-events: none;
        opacity: 1;
        transition: opacity 200ms ease-in-out;
    }

    .ant-picker.ant-picker-focused {
        + .whole-period {
            opacity: 0;
            transition: none;
        }
    }

    &--has-value {
        .ant-picker-clear {
            opacity: 1;
        }
        .ant-picker-suffix {
            opacity: 0;
        }
        .whole-period {
            opacity: 0;
        }
    }

    &--has-no-value {
        .ant-picker:not(.ant-picker-focused) {
            .ant-picker-input, .ant-picker-range-separator {
                opacity: 0;
            }
        }
    }

    .ant-picker-range-separator{
        padding: 0 0;
    }
}
