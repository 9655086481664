@import '../abstract/variables';

.tab-list {
    &.tab-list-indent {
        margin-left: -24px;
        margin-right: -24px;
    }

    .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs-tab {
        margin-right: 0;
        width: 100%;
        justify-content: center;
        color: $blue-gray-60;

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                font-weight: 700;
                text-shadow: unset;
            }
        }
    }

    .ant-tabs-nav-list {
        width: 100%;
    }
}

.interview-sheet-tab-list {
    .ant-tabs-tab {
        width: unset;
        padding: 8px 16px;
        margin-left: 0;
    }

    &.tab-with-less-padding {
        .ant-tabs-tab {
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .ant-tabs-nav-wrap {
        padding-left: 48px;
        padding-right: 48px;
        border-bottom: 1px solid $blue-gray-20;
    }

    &.tab-with-indent {
        .ant-tabs-nav-wrap {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .ant-tabs-nav {
        &:before {
            display: none;
        }
    }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    transition: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
}
