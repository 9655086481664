@import '../abstract/variables';
@import '../abstract/mixin';

.ant-modal-header {
    border-bottom: none;
    padding: 16px;

    .ant-modal-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
    }
}
.ant-modal-close {
    color: #666666;
}
.ant-modal-body {
    padding: 16px;
}

.router-prompt {
    .ant-modal-footer {
        border-top: 0;
    }
}

.modal-hidden-label .ant-col.ant-form-item-label label {
    display: none;
}

.terms-of-use-wrapper .ant-modal-content {
    overflow-y: scroll;
    @include ResponsiveMaxWidth(XS) {
        max-height: 400px;
        overflow: scroll;
    }
}

.ant-modal-mask,
.ant-modal-wrap {
    z-index: 10000;
}

.preview-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-modal {
        top: 0;
        height: 100%;
        padding: 16px 0;
        max-height: 687px;
    }

    .ant-modal-content {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .ant-modal-body {
        display: flex;
        flex: 1;
        overflow: hidden;
    }

    .render-page-container {
        padding: 8px 32px;
        max-height: 600px;
    }
}

.modal-footer-no-border {
    .ant-modal-footer {
        border-top: 0;
        padding-top: 0;
    }
}
