@import '../abstract/mixin';
@import '../abstract/variables';

.float-bar {
    position: fixed;
    bottom: 0;
    left: 220px;
    right: 0;
    height: 80px;
    border-top: 1px solid #d0d0d0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    z-index: 9999;
    .ant-btn {
        min-width: 120px;
        justify-content: center;
    }

    &.left-2side {
        left: 484px !important;
    }

    @include ResponsiveMaxWidth(MD) {
        left: 0;
    }
    @include ResponsiveTablet(IpadPro) {
        left: 220px;
    }

    @include ResponsiveMaxWidth(SM) {
        @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
            padding-bottom: calc(constant(safe-area-inset-bottom) + 16px);
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
            height: auto;
        }
    }
}.top-bar{
     position: fixed;
     top: 0;
     left: 220px;
     right: 0;
     height: 80px;
     border-top: 1px solid #d0d0d0;
     display: flex;
     flex-direction: row-reverse;
     align-items: center;
     padding: 16px;
     background-color: #ffffff;
     z-index: 9999;
     .ant-btn {
         min-width: 120px;
         justify-content: center;
     }
 }

.client-render-page-container {
    @include ResponsiveMaxWidth(SM) {
        @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
        }
    }
}

.tdt-content {
    @include ResponsiveMaxWidth(SM) {
        @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
            margin-bottom: calc(constant(safe-area-inset-bottom) + 48px);
            margin-bottom: calc(env(safe-area-inset-bottom) + 48px);
        }
    }
}

.sticky-container.stick {
    .seminar-detail-action-panel {
        @include ResponsiveMaxWidth(SM) {
            @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
                padding-bottom: calc(constant(safe-area-inset-bottom) + 6px) !important;
                padding-bottom: calc(env(safe-area-inset-bottom) + 6px) !important;
                height: auto !important;
            }
        }
    }
}

.loader-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
