@import '../abstract/variables';

.tree-searchable {
    //border: 1px solid $blue-gray-20;

    .ant-tree {
        font-size: 14px;
        line-height: 21px;
        color: $blue-gray-100;

        .ant-tree-node-content-wrapper {
            cursor: default;
            padding-left: 0;
        }

        .ant-tree-node-selected {
            background-color: transparent;
        }

        .ant-tree-treenode {
            padding: 4px 0 4px 0;
            width: 100% !important;
        }

        .ant-tree-node-content-wrapper, .ant-tree-title {
            width: 100% !important;;
            flex: 1;
        }

        .ant-tree-title {
            > span {
                width: 100% !important;
            }
        }
    }

    .ant-tabs-ink-bar-animated {
        transition: unset !important;
    }

    .label-count {
        font-size: 12px;
        line-height: 16px;
        color: $blue-gray-60;
        padding-left: 4px;
    }

    .ant-tree-treenode-disabled {
        .label-count, .svg-icon {
            color: $blue-gray-30;
        }
    }

    .ant-tabs-nav-wrap {
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: none;

        .ant-tabs-tab {
            padding: 11px 14px !important;
        }

        .ant-tabs-tab-disabled {
            color: $blue-gray-15;
        }
    }

    .tab-list .ant-tabs-tab {
        width: unset;

        & + .ant-tabs-tab {
            margin-left: 0;
        }
    }

    .with-search-box {
        .input-search,
        .ant-input {
            background-color: #fff;
        }

        .input-search {
            height: 40px;
        }

        .ant-input {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .tree-list-container {
        max-height: 220px;
        overflow-y: auto;
    }
}

.tree-searchable-as-table {
    .ant-table-tbody, .ant-table-pagination {
        display: none;
    }

    .with-search-box {
        padding: 0 !important;
        margin-bottom: 8px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
        padding: 9px 8px !important;
        border-top: none !important;

        &.ant-table-selection-column {
            padding-left: 22px !important;
            padding-right: 0 !important;
            // TODO: remove
            .ant-checkbox {
                opacity: 0;
            }
        }
    }

    .tree-list-container:not(.tree-without-thead-checkbox) {
        .ant-tree-switcher-noop {
            width: 30px;
        }
    }

    .tree-without-thead-checkbox .ant-table.ant-table-middle {
        colgroup .ant-table-selection-col {
            width: 16px;
        }

        .ant-table-thead > tr > th.ant-table-selection-column {
            padding-left: 0 !important;
        }
    }
}
