@import '../abstract/mixin';
@import '../abstract/variables';

h1.page-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 48px;
    line-height: 36px;
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
    }
    @include ResponsiveMaxWidth(MD) {
        margin-top: 8px !important;
        margin-bottom: 29px !important;
    }
}

.h6 {
    font-size: 20px;
    line-height: 30px;
}

.h7 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
}

.subtitle1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.subtitle2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
}

.body1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.body2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.caption {
    font-size: 12px;
    line-height: 18px;
}

.f-24-36 {
    font-size: 24px;
    line-height: 36px;
}

.f-16-30 {
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
}

.f-16-23 {
    font-size: 16px;
    line-height: 23px;
}

.f-16-24 {
    font-size: 16px;
    line-height: 24px;
}

.f-14-700 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
}

.f-14-18 {
    font-size: 14px;
    line-height: 18px;
}

.paragraph {
    @include ResponsiveMaxWidth(MD) {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 21px !important;
    }
}

.date-time-typo {
    @include ResponsiveMaxWidth(MD) {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $color-medium;
    }
}
