@import '../abstract/variables';
@import '../abstract/mixin';

.tdt-avatar {
    position: relative;
    display: inline-block;

    .tdt-avatar-badge {
        position: absolute;
        bottom: 0;
        right: 0;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .anticon {
            zoom: 0.6;
        }
    }
}

.not-found-image {
    width: 500px;
    height: 50%;
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
        height: 100%;
    }
}
