$blue-gray-100: #222126;
$blue-gray-90: #37353d;
$blue-gray-80: #4c4a55;
$blue-gray-60: #767384;
$blue-gray-50: #8d8a99;
$blue-gray-40: #a3a1ad;
$blue-gray-30: #bab9c2;
$blue-gray-20: #d1d0d6;
$blue-gray-15: #dcdce1;
$blue-gray-10: #e8e8eb;
$blue-gray-5: #f3f3f5;
$blue-purple-100: #4c18ed;
$blue-purple-80: #7046f1;
$blue-purple-60: #9474f4;
$blue-purple-40: #b7a3f8;
$blue-purple-20: #dbd1fb;
$blue-purple-10: #ede8fd;
$blue-100: #0088ff;
$primary: #4c18ed;
$pink: #e1338d;
$secondary: #13cbcb;
$secondaryVariant: #cfd8dc;
$ellipse-red: #E67B73;
$ellipse-dark-blue: #3F50B5;
$ellipse-yellow: #F5BF25;
$ellipse-light-blue: #4284F3;
$ellipse-green: #7CB342;
$error: #e1338d;
$pink: #e1338d;
$gray-separator: #d0d0d0;
$gray-input: #f4f4f4;
$field-error: #ff4d4f;
$red-100: #e1338d;
$blue-gray-100: #222126;
$aqua-spring: #e8f5e9;
$dark-lime-green: #4caf50;
$lavender-blush: #ffe8f4;

$color-high: #212121;
$color-medium: #666666;
$color-disabled: #8c8c8c;
$color-outline: #d0d0d0;
$bg-gray: #f4f4f4;

$color-placeholder: #999999;

$black-high: #212121;
$outline: #c2c2c2;
$disabled: #8c8c8c;

$disabled-placeholder: #afafaf;
$white: #ffffff;
