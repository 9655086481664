@import '../../abstract/variables';

.account-settings {
    .main-content {
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
    }

    .sub-content {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
    @include ResponsiveMaxWidth(XS) {
        width: 100%;
        margin-left: 0px !important;
    }
}

.resend-verify-code-spin {
    zoom: 0.7;
    margin-left: 8px;
}

.ant-form-item-control-input-content {
    .w-480 {
        @include ResponsiveMaxWidth(SM) {
            width: 100% !important;
        }
    }
}

.account-deleted {
    &-container {
        padding-top: 80px;

        @include mobile {
            padding-top: 64px;
        }
    }

    .td-header {
        line-height: 50px;

        @include mobile {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
        }
    }

    &-text {
        font-size: 14px;
        line-height: 24px;
    }

    .contact-info-label {
        color: $blue-gray-60;
        font-size: 12px;
        line-height: 20px;
    }

    .contact-info-as-link {
        font-size: 18px;
        line-height: 24px;
        color: $blue-purple-100;

        a {
            text-decoration: underline;
        }
    }
}

.delete-btn-mobile {
    @include ResponsiveMaxWidth(SM) {
        width: 100% !important;
        justify-content: center;
    }
}

.account-deleted-mobile {
    .inquiry-note-title {
        @include ResponsiveMaxWidth(SM) {
            padding-left: 32px;
        }
    }

    .account-deleted-mobile-info-icon {
        @include ResponsiveMaxWidth(SM) {
            position: absolute;
        }
    }

    .account-deleted-mobile-indent {
        @include ResponsiveMaxWidth(SM) {
            text-indent: -16px;
            padding-left: 16px;
        }
    }
}