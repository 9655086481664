.ant-checkbox-inner {
    width: 18px;
    height: 18px;
}

.ant-checkbox-inner {
    border: 2px solid #767384;
    border-radius: 2px;
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        border-color: transparent;
    }
}

// tree checkbox
.ant-tree-checkbox-inner {
    width: 16px;
    height: 16px;
    border: 2px solid #767384;
    border-radius: 2px;

    &::after {
        left: 18%;
    }
}

.ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
        border-color: transparent;
    }
}
