@import '../../abstract/variables';

.dashboard {
    .icon-circle {
        border-radius: 32px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.sub-color-100 {
            background-color: $secondary;
            color: #fff;
        }

        &.gray-info {
            color: $blue-gray-60;
        }
    }

    .notice-row {
        border-bottom: 1px solid $blue-gray-20;
        position: relative;
        cursor: pointer;

        .arrow-right {
            position: absolute;
            right: 0;
            top: 24px;
            color: $blue-gray-60;
        }
    }
}
