@import '../../abstract/variables';
@import '../../abstract/mixin';

.question-block {
  padding: 24px 24px;
  background-color: $white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .question-title {
    font-size: 20px;
  }

  .item {
    margin-bottom: 24px;

    .label {
      font-weight: bold;
    }

    .ant-input,
    .ant-select-selector {
      background-color: #ffffff;
    }

    .ant-checkbox + span {
      padding-right: 0;
    }
  }
}

.interview-sheet-preview {
  border-top: 1px solid #d0d0d0;
  padding: 24px;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  .description {
    white-space: pre-line;
  }

  .note {
    font-size: 14px;
    color: $error;
  }

  .daily-life-events {
    button {
      border: 1px solid $blue-gray-20;
      padding: 2.4px 15px;
    }
  }
}

.interview-icon {
  width: 5%;
  @include Responsive(XL) {
    margin-right: 0 !important;
  }
}

.interview-btn {
  width: 5%;
  margin-left: 0 !important;
  //padding-left: 15.5%;
  @include Responsive(XL) {
    .boxes-button {
      width: 100%;

      button {
        width: 100% !important;
      }
    }
  }
  @include ResponsiveMaxWidth(XL) {
    width: 13%;
  }
  @include ResponsiveMaxWidth(MD) {
    width: 19%;
    padding-left: 10px;
  }
  @include ResponsiveMaxWidth(SM) {
    width: 22%;
    padding-left: 0px;
  }
  @include ResponsiveMaxWidth(XS) {
    margin-top: 8px;
    width: 100%;
  }
}

.boxes-col {
  width: 87%;
  display: inline;
  float: left;
  padding-left: 15px;

  .interview-date {
    width: 15%;
    float: left;
  }

  .interview-title {
    width: 80%;
    float: left;
  }

  @include ResponsiveMaxWidth(XL) {
    width: 79%;
    .interview-date {
      width: 20%;
      float: left;
    }

    .interview-title {
      width: 75%;
      float: left;
      padding-left: 2.5%;
    }
  }
  @include ResponsiveMaxWidth(MD) {
    width: 73%;
    padding-left: 30px;
  }
  @include ResponsiveMaxWidth(SM) {
    width: 68%;
  }
  @include ResponsiveMaxWidth(XS) {
    width: 80%;
    display: flex;
    flex-direction: column;
    .interview-date {
      width: 100%;
      order: 2;
      padding-top: 3%;
      @include ResponsiveMaxWidth(XS) {
        display: flex;
        flex-direction: row;
        .interview-datetime {
          order: 2;
          padding-left: 10px;
          font-weight: 300 !important;
        }
        .interview-status {
          font-weight: bold !important;
          order: 1;
        }
      }
    }

    .interview-title {
      width: 100%;
      order: 1;
      padding-left: 0;

      .interview-title-war {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: $blue-gray-60;
        margin-bottom: 2px;
      }
    }
  }
}

.interview-sheet-description {
  @include ResponsiveMaxWidth(SM) {
    margin-bottom: 0 !important;
  }
}

.itv-reservation-status {
  @include ResponsiveMaxWidth(XS) {
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 18px;
    color: $blue-gray-40;
  }
}

.itv-date-time {
  @include ResponsiveMaxWidth(XS) {
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: $blue-gray-60;
  }
}

.itv-btn {
  @include ResponsiveMaxWidth(XS) {
    padding-top: 12px;
  }
}

.itv-reservation-title {
  @include ResponsiveMaxWidth(XS) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}

.itv-history-btn {
  @include ResponsiveMaxWidth(MD) {
    margin-top: 8px !important;
    margin-bottom: 29px !important;
  }
}

.modal-interview-sheet-prv {
  margin-top: 24px;
  padding-left: 16px;
  padding-right: 16px;

  .interview-sheet-preview {
    border-top: 0;
    padding: 16px;

    .question:last-child {
      border-bottom: 0;
    }
  }
}

.answer-question {
  background: $blue-gray-5;
  padding: 8px 12px;
  display: flex;
  min-height: 40px;
  flex-direction: row;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
}

.ans-with-count {
  @extend .answer-question;
  width: max-content;
  margin-bottom: 0;
}

.ans-counting {
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 24px;
  padding: 2px 4px;
  background: $blue-purple-20;

  font-weight: 700;
  font-size: 14px;
  color: $blue-purple-100;
  border-radius: 4px;
}

.symbolic-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;

  width: 12px;
  height: 12px;

  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.good-symbol {
  @extend .symbolic-color;
  background: $secondary;
}

.bad-symbol {
  @extend .symbolic-color;
  background: $blue-100;
}

.nand-symbol {
  @extend .symbolic-color;
  background: $blue-purple-80;
}

.gray-symbol {
  @extend .symbolic-color;
  background: $blue-gray-10;
}

.horizontal-bar-chart {

  &-percent {
    background: $blue-purple-100;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: $blue-gray-90;
    height: 28px;
    padding: 4px 8px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
  }
}

.percent-as-zero {
  color: $blue-purple-100;
  padding: 4px 2px;
}

.percent-of-other {
  //opacity: 0.8;
}

.percent-number {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: $blue-gray-90;
  width: 0;
  white-space: nowrap;
}

.by-score-row {
  &:nth-child(even) {
    background-color: $blue-gray-5;
  }
}

.detail-has-validation-msg {
  margin-bottom: 0;

  &.ant-form-item-has-error {
    .question {
      padding-bottom: 68px;

      @include ResponsiveMaxWidth(XS) {
        padding-bottom: 40px;
      }
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    bottom: 38px;
    font-size: 14px;
    @include ResponsiveMaxWidth(XS) {
      bottom: 12px;
    }
  }
}

.question-daily-life {
  .ant-form-item-explain.ant-form-item-explain-error {
    position: unset;
    margin-bottom: 16px;
  }
}

.position-top-right-float-bar {
  width: 156px;
  height: 36px;
  padding-right: 11px;
  float: right;
  position: relative;
  top: -25px;
  left: 25px;
  background-color: $white;
  border: 1px solid $blue-gray-20;
}

.position-right-button {
  position: relative;
  text-align: right;
}

.small-note-interview-sheet {
  border: 1px solid $blue-gray-15;
  border-radius: 25px;
  width: fit-content;
}

.text-small-note-interview-sheet {
  margin: 4px 8px;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
}

.time-badge {
  border-radius: 4px;
  background-color: $blue-purple-10;
  width: fit-content;

  &.periodic-diagnosis-disabled {
    background-color: $blue-gray-10;
  }
}

.p4-8 {
  padding: 4px 8px;
}

.category-float-bar {
  border-radius: 0 4px;

  .tdt-button-disabled {
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: $blue-gray-20 !important;
    height: auto;
    line-height: inherit;
  }
}

.itv-disable-by-readonly {

  .ant-input, .ant-input-number-input {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
    pointer-events: none;
  }

  .session-edit-wrapper {
    pointer-events: none;

    button {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .ant-dropdown-trigger {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .question-block {
    .ant-select {
      pointer-events: none;

      * {
        pointer-events: none;
      }
    }
  }
}

.ant-tooltip-placement-topRight {
  z-index: 9999;
}