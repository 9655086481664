.vjs-button {
  outline: none;
}

.vjs-theme-custom .vjs-big-play-button {
  width: 70px;
  height: 70px;
  background: none;
  line-height: 70px;
  font-size: 80px;
  border: none;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
}

.vjs-theme-custom.vjs-big-play-button:focus, .vjs-theme-custom:hover .vjs-big-play-button {
  background-color: transparent;
  color: #fff
}