@import 'variables';
@import 'mixin';

$i: 0;
@while $i < 100 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  .px-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
  $i: $i + 2;
}

.ml--4 {
  margin-left: -4px;
}

.ml--8 {
  margin-left: -8px;
}

.mb--8 {
  margin-bottom: -8px;
}

.mb--60 {
  margin-bottom: -60px !important;
}

.mt--16 {
  margin-top: -16px;
}

.ml--24 {
  margin-left: -24px;
}

.mr--24 {
  margin-right: -24px;
}

.mb--24 {
  margin-bottom: -24px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.w-100p {
  width: 100% !important;
}

.w-50p {
  width: 50% !important;
}

.h-100p {
  height: 100% !important;
}

.h-40 {
  height: 40px !important;
}

.h-88 {
  height: 88px !important;
}

.h-unset {
  height: unset !important;
}

.w-480 {
  width: 480px !important;
}

.w-80 {
  width: 80px !important;
}

.mw-56 {
  max-width: 56px !important;
}

.mw-58 {
  max-width: 58px !important;
}

.min-w-350 {
  @include Responsive(XL) {
    min-width: 350px !important;
  }
}

.c-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.fw-b {
  font-weight: bold !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.fs-14-20 {
  font-size: 14px;
  line-height: 20px;
}

.fs-14-21 {
  font-size: 14px !important;
  line-height: 21px !important;
}

.fs-12-12 {
  font-size: 12px;
  line-height: 12px;
}

.fs-12-15 {
  font-size: 12px;
  line-height: 15px;
}

.fs-12-17 {
  font-size: 12px;
  line-height: 17px;
}

.f16-24 {
  font-size: 16px;
  line-height: 24px;
}

.f20-30 {
  font-size: 20px;
  line-height: 30px;
}

.f20-36 {
  font-size: 20px;
  line-height: 36px;
}

.f-primary {
  font-size: 14px;
  line-height: 21px;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.text-gray-color {
  color: $blue-gray-60 !important;
}

.primary-color {
  color: $primary !important;
}

.high-color {
  color: $color-high !important;
}

.pink-color {
  color: $pink !important;
}

.white-color {
  color: #fff !important;
}

.medium-color {
  color: $color-medium !important;
}

.disable-color {
  color: $blue-gray-40 !important;
}

.border-validate {
  border-color: $red-100;

  &:hover {
    border-color: $red-100;
  }

  &:focus {
    border-color: $red-100;
    box-shadow: none;
  }
}

.outline-error {
  outline: 1px solid $red-100;
}

.text-placeholder-color {
  color: $color-placeholder;
}

.text-small {
  font-size: 12px;
  line-height: 18px;
  color: $blue-gray-60;
}

.text-decoration-none {
  text-decoration: none !important;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline-block {
  display: inline-block !important;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.bg-aqua-spring {
  background-color: $aqua-spring !important;
}

.bg-gray {
  background-color: #f4f4f4 !important;
}

.bg-gray-5 {
  background-color: $blue-gray-5 !important;
}

.bg-gray-10 {
  background-color: $blue-gray-10 !important;
}

.bg-gray-90 {
  background-color: $blue-gray-90 !important;
}

.bg-blue-purple-10 {
  background-color: $blue-purple-10 !important;
}

.bg-blue-purple-100 {
  background-color: $blue-purple-100 !important;
}

.bg-lavender-blush {
  background-color: $lavender-blush !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.opacity-0 {
  opacity: 0;
}

.flex-1 {
  flex: 1;
}

.flex-end {
  align-items: flex-end;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-direction-unset {
  flex-direction: unset !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.btn-w64 {
  width: 64px !important;
  height: 45px !important;
}

.btn-w120 {
  width: 120px !important;
  height: 45px !important;
}

.border-bottom-separator {
  border-bottom: 1px solid $color-outline;
}

.border-bottom-primary {
  border-bottom: 1px solid $primary;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-none {
  border: none !important;
}

.color-white {
  color: #fff;
}

.color-blg-100 {
  color: $blue-gray-100;
}

.color-blg-20 {
  color: $blue-gray-20 !important;
}

.color-blg-30 {
  color: $blue-gray-30;
}

.color-blg-40 {
  color: $blue-gray-40 !important;
}

.color-blg-50 {
  color: $blue-gray-50;
}

.color-blg-60 {
  color: $blue-gray-60;
}

.color-blg-80 {
  color: $blue-gray-80 !important;
}

.color-red-100 {
  color: $red-100;
}

.color-blue-100 {
  color: $blue-purple-100 !important;
}

.color-blg-30-imp {
  color: $blue-gray-30 !important;
}

.color-dlg {
  color: $dark-lime-green !important;
}

.color-ellipse-red {
  color: $ellipse-red !important;
}

.color-ellipse-dark-blue {
  color: $ellipse-dark-blue !important;
}

.color-ellipse-yellow {
  color: $ellipse-yellow !important;
}

.color-ellipse-light-blue {
  color: $ellipse-light-blue !important;
}

.color-ellipse-green {
  color: $ellipse-green !important;
}

.hover-color-blg-80 {
  &:hover {
    color: $blue-gray-80;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-all;
}

.color-dot-index {
  &-0 {
    color: #F66155;
  }
  &-1 {
    color: #7E0CE8;
  }
  &-2 {
    color: #F4BE26;
  }
  &-3 {
    color: #3D9FF5;
  }
  &-4 {
    color: #0CDF32;
  }
  &-5 {
    color: #009688;
  }
  &-6 {
    color: #795548;
  }
  &-7 {
    color: #607d8b;
  }
}

//
.eclipse-status {
  width: 8px;
  height: 8px;
  border-radius: 32px;
  display: inline-block;

  &-0 {
    background-color: $blue-100;
  }

  &-1 {
    background-color: #78ba5d;
  }

  &-2 {
    background-color: #eed648;
  }

  &-3 {
    background-color: #f2a240;
  }

  &-4 {
    background-color: #da634f;
  }

  &-5 {
    background-color: #b87cda;
  }

  &-6 {
    background-color: #3378ba;
  }
}

.z-index-above-modal {
  z-index: 10001;
}

.z-index-10000 {
  z-index: 10000;
}

.z-index-horizontal-line {
  z-index: -1000 !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.line-bg-20 {
  border-bottom: 1px solid $blue-gray-20;
}
